import {
  LOADING_ROLE,
  GET_ROLES,
  ADD_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  FILTER_ROLE,
  ROLE_ERROR,
} from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { apiEndpoint } from "../Global";
import toast from "react-hot-toast";

// Load Roles
export const getRoles = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_ROLE });
    const res = await axios.get(`${apiEndpoint}/roles`);

    dispatch({
      type: GET_ROLES,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: ROLE_ERROR,
      payload: err.response.data,
    });
  }
};

// Create Role
export const createRole = (role) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_ROLE });
    const res = await axios.post(`${apiEndpoint}/roles/create`, role, config);

    dispatch({
      type: ADD_ROLE,
      payload: res.data.data,
    });
    toast.success(res.data.message);
    role.props.onHide();
  } catch (err) {
    dispatch({
      type: ROLE_ERROR,
      payload: err.response.data,
    });
  }
};

// UPDATE ROLE
export const updateRole = (role) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_ROLE });
    const res = await axios.put(
      `${apiEndpoint}/roles/update/${role.id}`,
      role,
      config
    );

    dispatch({
      type: UPDATE_ROLE,
      payload: res.data.data,
    });
    toast.success(res.data.message);
    role.props.onHide();
  } catch (err) {
    dispatch({
      type: ROLE_ERROR,
      payload: err.response.data,
    });
  }
};

// FILTER ROLE
export const filteredRole = (search) => (dispatch) =>
  dispatch({ type: FILTER_ROLE, payload: search });

// DELETE ROLE
export const deleteRole = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_ROLE });
    const res = await axios.delete(`${apiEndpoint}/roles/delete/${id}`);

    dispatch({
      type: DELETE_ROLE,
      payload: id,
    });
    toast.success(res.data.message);
  } catch (err) {
    dispatch({
      type: ROLE_ERROR,
      payload: err.response.data,
    });
  }
};
