import {
  LOADING_ACTIVITY,
  GET_ACTIVITIES,
  ACTIVITY_ERROR,
} from "../actions/types";

const INITIALSTATE = {
  activities: [],
  next_page: "",
  previous_page: "",
  last_page: 0,
  per_page: 0,
  total_pages: 0,
  filtered: null,
  loading: false,
  error: null,
};

const activityReducer = (state = INITIALSTATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_ACTIVITY:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVITIES:
      return {
        ...state,
        activities: payload.data,
        next_page: payload.next_page_url,
        last_page: payload.last_page,
        previous_page: payload.previous_page_url,
        per_page: payload.per_page,
        total_pages: payload.total,
        loading: false,
      };
    case ACTIVITY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default activityReducer;
