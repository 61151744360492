import {
  ADD_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  GET_DEPARTMENTS,
  DEPARTMENTS_ERROR,
  LOADING_DEPARTMENT,
  FILTER_DEPARTMENTS,
  SET_CURRENT,
  CLEAR_CURRENT,
} from "../actions/types";

const initialState = {
  departments: [],
  nextPage: "",
  previousPage: "",
  perPage: 0,
  totalPages: 0,
  filtered: null,
  current: null,
  loading: false,
  error: null,
};

const departmentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: payload.data,
        nextPage: payload.next_page_url,
        previousPage: payload.previous_page_url,
        perPage: payload.per_page,
        totalPages: payload.totalPages,
        loading: false,
      };
    case ADD_DEPARTMENT:
      return {
        ...state,
        departments: [payload, ...state.departments],
        current: null,
        loading: false,
      };
    case UPDATE_DEPARTMENT:
      return {
        ...state,
        departments: state.departments.map((department) =>
          department.id === payload.id ? payload : department
        ),
        current: null,
        loading: false,
      };
    case FILTER_DEPARTMENTS:
      return {
        ...state,
        filtered: state.departments.filter((department) => {
          const regex = new RegExp(`${payload}`, "gi");
          return department.name.match(regex);
        }),
        loading: false,
      };
    case DELETE_DEPARTMENT:
      return {
        ...state,
        departments: state.departments.filter(
          (department) => department.id !== payload.id
        ),
        current: null,
        loading: false,
      };
    case DEPARTMENTS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        current: null,
      };
    case LOADING_DEPARTMENT:
      return {
        ...state,
        loading: true,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: payload,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
};

export default departmentReducer;
