import { combineReducers } from "redux";
import authReducer from "./authReducer";
import productReducer from "./productReducer";
import roleReducer from "./roleReducer";
import leadReducer from "./leadReducer";
import dealReducer from "./dealReducer";
import departmentReducer from "./departmentReducer";
import transactionReducer from "./transactionReducer";
import activityReducer from "./activityReducer";
import teamReducer from "./teamReducer";
import locationReducer from "./locationReducer";
import recordReducer from "./recordReducer";

export default combineReducers({
  auth: authReducer,
  product: productReducer,
  lead: leadReducer,
  department: departmentReducer,
  deal: dealReducer,
  team: teamReducer,
  role: roleReducer,
  transaction: transactionReducer,
  activity: activityReducer,
  location: locationReducer,
  record: recordReducer
});
