import React from "react";

import ServerDataTable from "./DataTable/UsersDataTable";

import {
  Badge,
  Table
} from "@themesberg/react-bootstrap";


import AdvancedFilter from "../components/AdvancedFilter/AdvancedFilter";

import { Modal } from "@themesberg/react-bootstrap";

import moment from "moment-timezone";
import "./Users.css";

const UserDealModal = (props) => {
  const { userDeals } = props;

  const columns = [
    {
      name: "Customer",
      selector: (row) => row.customer,
      style: {
        fontWeight: "bold",
      },
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Email",
      selector: (row) => row.email && row.email,
      // sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.company,
    },
    {
      name: "Position",
      selector: (row) => row.position,
    },
    {
      name: "Status",
      cell: (row) => (
        <Badge bg={row.status ? "success" : "warning"}>
          {row.status ? "Active" : "Closed"}
        </Badge>
      ),
      // style: {
      //   flexGrow: 1,
      // },
    },
    {
      name: "Created At",
      selector: (row) => moment.utc(row.created_at).format("ll"),
      sortable: true,
    },
    {
      name: "Updated At",
      selector: (row) => moment.utc(row.updated_at).format("ll"),
      sortable: true,
    },
   
  ];

  const TableRow = (props) => {
    const {
      customer,
      company,
      phone,
      email,
      position,
      created_at,
      updated_at,
    } = props;

    return (
      <tr>
        <td>
          <span className='fw-normal'>{customer}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{company}</span>
        </td>
        <td>
          <span className='fw-normal'>{position}</span>
        </td>
        <td>
          {props.role === null ? (
            <Badge bg='danger'>Not Active</Badge>
          ) : (
            <Badge bg='success'>Active</Badge>
          )}
        </td>

        <td>
          <span className='fw-normal'>
            {moment.utc(created_at).format("ll")}
          </span>
        </td>
        <td>
          <span className='fw-normal'>
            {moment.utc(updated_at).format("ll")}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      size='xl'
      fullscreen={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          LIST OF LEADS
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AdvancedFilter />
        <ServerDataTable
          title={"User Deals"}
          data={userDeals}
          columns={columns}
          className='mt-3 mb-5'
          totalRows={userDeals}
        />

        <Table hover className='user-table align-items-center'>
          <thead>
            <tr>
              <th className='border-bottom'>Fullname</th>
              <th className='border-bottom'>Phone</th>
              <th className='border-bottom'>Email</th>
              <th className='border-bottom'>Company</th>
              <th className='border-bottom'>Position</th>
              <th className='border-bottom'>Status</th>
              <th className='border-bottom'>Created Date</th>
              <th className='border-bottom'>Updated Date</th>
            </tr>
          </thead>
          <tbody>
            {userDeals &&
              userDeals.map((deal) => (
                <TableRow
                  key={deal.id}
                  {...deal}
                  setModalShow={props.setModalShow}
                  setUpdate={props.setUpdate}
                  setUser={props.setUser}
                  dispatch={props.dispatch}
                />
              ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default UserDealModal;
