import {
  faBriefcase,
  faEnvelope,
  faPhoneAlt,
  faUnlockAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormCheck,
  FormGroup,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/sujimoto-logo.png";
import Alert from "../../components/Alert";
import { Routes } from "../../routes";

import { connect } from "react-redux";
import { register } from "../../actions/authActions";

const Signup = ({ auth: { loading, isAuthenticated }, register }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    position: "",
  });

  const { firstname, lastname, email, phone, password, position } = user;

  const positions = [
    { name: "Sales Executive", value: "Sales Executive" },
    { name: "Supervisor", value: "Supervisor" },
    { name: "Team Lead", value: "Team Lead" },
    { name: "Investment Vendor", value: "Investment Vendor" },
    { name: "PV", value: "Promotion Vendor" },
    { name: "Sales Finance", value: "Sales Finance" },
    { name: "Admin", value: "Admin" },
    { name: "HOD", value: "HOD" },
    { name: "ED", value: "ED" },
    { name: "Others", value: "Others" },
  ];

  const validEmail = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const validName = new RegExp(/^[a-zA-Z]+ [a-zA-Z]+$/);

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (firstname === "") {
      Alert("First name is required", "error");
    } else if (lastname === "") {
      Alert("Last name is required", "error");
    } else if (email === "") {
      Alert("Email is required to create an account", "error");
    } else if (!validEmail.test(email)) {
      Alert("Please include a valid email address", "error");
    } else if (phone === "") {
      Alert("Phone number is required", "error");
    } else if (phone.length < 11 || phone.length > 11) {
      Alert("Please include a valid phone number", "error");
    } else if (password === "") {
      Alert("Password is required", "error");
    } else if (password.length < 6) {
      Alert("Password should be 6 chars. or more", "error");
    } else if (position === "") {
      Alert("Please include your position", "error");
    } else {
      register(user);
    }
  };

  return (
    <main>
      <section className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
        <Container>
          <p className='text-center'>
            <Image
              src={Logo}
              className='bg-fluid my-3'
              width={250}
              height={60}
              style={{ objectFit: "contain" }}
            />
          </p>
          <Row className='justify-content-center'>
            <Col
              xs={12}
              className='d-flex align-items-center justify-content-center'
            >
              <div className='mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                <div className='text-center text-md-center mb-4 mt-md-0'>
                  <h3 className='mb-0'>Create an account</h3>
                </div>
                <Form className='mt-5 mb-5' onSubmit={onSubmit}>
                  <Form.Group id='firstname' className='mb-4'>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type='text'
                        value={firstname}
                        name='firstname'
                        placeholder='First name'
                        onChange={onChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id='lastname' className='mb-4'>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type='text'
                        value={lastname}
                        name='lastname'
                        placeholder='Last name'
                        onChange={onChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id='email' className='mb-4'>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        type='text'
                        name='email'
                        value={email}
                        placeholder='Email Address'
                        onChange={onChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id='text' className='mb-4'>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPhoneAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        type='text'
                        name='phone'
                        maxLength={11}
                        value={phone}
                        placeholder='e.g 08012345679'
                        onChange={onChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  {/* <Form.Group id='position' className='mb-4'>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faBriefcase} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type='text'
                        value={position}
                        name='position'
                        placeholder='Position'
                        onChange={onChange}
                      />
                    </InputGroup>
                  </Form.Group> */}
                  <FormGroup className='my-4'>
                    <InputGroup>
                      <Form.Select
                        name='position'
                        value={position}
                        onChange={onChange}
                      >
                        <option value=''>Select a Position</option>
                        {positions.map((position) => (
                          <option value={position.value} key={position.name}>
                            {position.name}
                          </option>
                        ))}
                      </Form.Select>
                    </InputGroup>
                  </FormGroup>
                  <Form.Group id='password' className='mb-4'>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        type='password'
                        name='password'
                        value={password}
                        placeholder='Password'
                        onChange={onChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Button
                    variant='primary'
                    type='submit'
                    disabled={loading ? true : false}
                    className='w-100 text-uppercase'
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                        />
                        <span className='text-capitalize'> Authenticating</span>
                      </>
                    ) : (
                      "Sign up"
                    )}
                  </Button>
                </Form>

                <div className='d-flex justify-content-center align-items-center mt-4'>
                  <span className='fw-normal'>
                    Already have an account?
                    <Card.Link
                      as={Link}
                      to={Routes.Signin.path}
                      className='fw-bold'
                    >
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

Signup.propTypes = {
  register: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { register })(Signup);
