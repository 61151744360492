import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "react-lottie";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";

import {
  faCheck,
  faCog,
  faEllipsisH,
  faEdit,
  faTrashAlt,
  faEye,
  faHome,
  faSearch,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Modal,
  Card,
  Table,
  Spinner,
  Nav,
  Pagination,
} from "@themesberg/react-bootstrap";
import { formatAmount } from "../Global";
import emptyProduct from "../assets/syntax-themes/93134-not-found";

import {
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  filteredRole,
} from "../actions/roleActions";
import { getDepartments } from "../actions/departmentActions";
import moment from "moment-timezone";
import { ExportToExcel } from "./ExportDeals";

const Roles = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [role, setRole] = React.useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getRoles());
    dispatch(getDepartments());
  }, [dispatch]);

  return (
    <>
      <div className='table-settings mb-4'>
        <Row className='justify-content-between align-items-center'>
          <Col xs={6} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Search'
                onChange={(e) => dispatch(filteredRole(e.target.value))}
              />
            </InputGroup>
          </Col>

          <Col xs={5} md={2} xl={2}>
            <Button
              variant='primary'
              type='button'
              className='w-100 text-uppercase'
              onClick={() => {
                setModalShow(true);
                setUpdate(false);
              }}
            >
              Add Role
            </Button>
          </Col>
        </Row>
      </div>

      <ExportToExcel
        // apiData={roles}
        // fileName={`lead_${current_page}`}
      />

      <RoleTable
        setModalShow={setModalShow}
        setUpdate={setUpdate}
        setRole={setRole}
        dispatch={dispatch}
      />
      <RoleModal
        dispatch={dispatch}
        show={modalShow}
        update={update}
        role={role}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Roles;

// Modal
const RoleModal = (props) => {
  const [role, setRole] = React.useState({
    departmentId: "",
    name: "",
  });
  const { loading } = useSelector((state) => state.role);
  const { departments } = useSelector((state) => state.department);

  const { name, departmentId } = role;

  const onSubmit = (e) => {
    e.preventDefault();
    props.update
      ? props.dispatch(
          updateRole({ id: props.role.id, name, departmentId, props })
        )
      : props.dispatch(createRole({ name, departmentId, props }));
  };

  React.useEffect(() => {
    setRole({
      name: props.role && props.role.name,
      departmentId: props.role && props.role.departmentId,
    });
  }, [props]);

  const onChange = (e) => setRole({ ...role, [e.target.name]: e.target.value });

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {`${props.update ? "Update" : "Add"} Role`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='name'
                value={name}
                placeholder='Enter Role Name'
                onChange={onChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Select
                name='departmentId'
                value={departmentId}
                onChange={onChange}
              >
                <option value=''>Choose a Department</option>
                {departments.map((department) => (
                  <option key={department.id} value={department.id}>
                    {department.name}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='text-white'
            disabled={name && departmentId ? false : true}
            variant='secondary'
            type='submit'
          >
            {loading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' />
                <span className='text-capitalize'> Submitting</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const RoleTable = (props) => {
  const { roles } = useSelector((state) => state.role);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rolePerPage] = React.useState(10);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyProduct,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { filtered } = useSelector((state) => state.role);

  const paginate = ({ selected }) => setCurrentPage(selected);

  const indexOfLastRole = currentPage * rolePerPage;
  const currentRoles = filtered
    ? filtered && filtered.slice(indexOfLastRole, indexOfLastRole + rolePerPage)
    : roles && roles.slice(indexOfLastRole, indexOfLastRole + rolePerPage);

  const pageCount = Math.ceil(
    filtered
      ? filtered && filtered.length / rolePerPage
      : roles && roles.length / rolePerPage
  );

  const TableRow = (props) => {
    const { name, created_at } = props;

    const onVerifyDelete = (id) => {
      swal({
        title: "Delete Role",
        text: "Do you want to delete? ",
        icon: "warning",
        buttons: ["Cancel", true],
        dangerMode: true,
      }).then((result) => {
        if (result) {
          props.dispatch(deleteRole(id));
        }
      });
    };

    return (
      <>
        <tr>
          <td>
            <span className='fw-normal'>{name}</span>
          </td>
          <td>
            <span className='fw-normal'>
              {moment.utc(created_at).format("ll")}
            </span>
          </td>

          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                as={Button}
                split
                variant='link'
                className='text-dark m-0 p-0'
              >
                <span className='icon icon-sm'>
                  <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    props.setModalShow(true);
                    props.setUpdate(true);
                    props.setRole(props);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit
                </Dropdown.Item>
                <Dropdown.Item
                  className='text-danger'
                  onClick={() => onVerifyDelete(props.id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Remove
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
        <ExportToExcel
          apiData={roles}
          // fileName={`lead_${current_page}`}
        />
      </>
    );
  };

  return (
    <>
      {currentRoles.length > 0 ? (
        <>
          <Card
            border='light'
            className='table-wrapper table-responsive shadow-sm'
          >
            <Card.Body className='pt-0'>
              <Table hover className='user-table align-items-center'>
                <thead>
                  <tr>
                    <th className='border-bottom'>Name</th>
                    <th className='border-bottom'>Created Date</th>
                    <th className='border-bottom'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRoles.map((role) => (
                    <TableRow
                      key={role.id}
                      {...role}
                      setModalShow={props.setModalShow}
                      setUpdate={props.setUpdate}
                      setRole={props.setRole}
                      dispatch={props.dispatch}
                    />
                  ))}
                </tbody>
              </Table>
              <div className='my-3' />
              {/* <Card.Footer className="border-0 d-lg-flex align-items-center justify-content-between"></Card.Footer> */}
            </Card.Body>
            <div className='paginate_container mx-auto'>
              <ReactPaginate
                previousLabel='Prev'
                nextLabel='Next'
                pageCount={pageCount}
                onPageChange={paginate}
                containerClassName={"paginationBtns"}
                previousLinkClassName={"previousBtn"}
                nextLinkClassName={"nextBtn"}
                disabledClassName={"disabledBtn"}
                activeClassName={"activeBtn"}
                pageRangeDisplayed={0}
                marginPagesDisplayed={0}
              />
            </div>
          </Card>
        </>
      ) : (
        <Lottie options={defaultOptions} height={300} width={300} />
      )}
    </>
  );
};
