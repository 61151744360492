import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Modal,
  Card,
  Spinner,
} from "@themesberg/react-bootstrap";
import moment from "moment-timezone";
import { formatAmount } from "../Global";
import {
  getTransaction,
  createTransaction,
  updateTransaction,
  clearTransaction,
} from "../actions/transactionActions";

const Transactions = (props) => {
  const [introduction, setIntroduction] = React.useState(false);
  const [inspection, setInspection] = React.useState(false);
  const [meeting, setMeeting] = React.useState(false);
  const [negotiation, setNegoitation] = React.useState(false);
  const [offer_toggle, setOffer_toggle] = React.useState(false);
  const [offer, setOffer] = React.useState("");
  const [meeting_summary, setMeetingSummary] = React.useState("");
  const [payment, setPayment] = React.useState("");

  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);
  const { transaction, loading } = useSelector((state) => state.transaction);

  const onSubmit = (e) => {
    e.preventDefault();
    transaction
      ? dispatch(
          updateTransaction({
            id: transaction.id,
            dealId: transaction.dealId,
            introduction,
            inspection,
            meeting,
            meeting_summary,
            negotiation: negotiation,
            offer,
            payment,
          })
        )
      : dispatch(
          createTransaction({
            dealId: props.deal.id,
            introduction,
            inspection,
            meeting,
            meeting_summary,
            negotiation: negotiation,
            offer,
          })
        );
  };

  const {
    deal: { lead, product, user },
    findUser,
  } = props;

  React.useEffect(() => {
    setIntroduction(transaction && transaction.introduction);
    setInspection(transaction && transaction.inspection);
    setNegoitation(transaction && transaction.negotiation);
    setMeeting(transaction && transaction.meeting);
    setOffer(transaction && transaction.offer);
    setOffer_toggle(transaction && transaction.offer && true);
    setMeetingSummary(transaction && transaction.meeting_summary);
    setPayment(transaction && transaction.payment);
  }, [transaction]);

  React.useEffect(() => {
    dispatch(getTransaction(props.deal.id));
  }, [dispatch, props]);

  return (
    <>
      <Button
        onClick={() => {
          props.onHide();
          dispatch(clearTransaction());
        }}
      >
        Back
      </Button>
      <Card border="light" className="shadow-sm mt-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Deal Information</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <span className="text-secondary">Assigned By</span>
              <h5>{`${findUser.firstname} ${findUser.lastname}`}</h5>
            </Col>
            <Col>
              <span className="text-secondary">Assigned To</span>
              <h5>{`${user.firstname} ${user.lastname}`}</h5>
            </Col>
            <Col>
              <span className="text-secondary">Position</span>
              <h5>{user.position}</h5>
            </Col>
            <Col>
              <span className="text-secondary">Date Assigned</span>
              <h5>{moment.utc(props.deal.created_at).format("ll")}</h5>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <span className="text-secondary">Lead</span>
              <h5>{props.deal.customer}</h5>
            </Col>
            <Col>
              <span className="text-secondary">Company</span>
              <h5>{props.deal.company}</h5>
            </Col>
            <Col>
              <span className="text-secondary">Phone</span>
              <h5>{props.deal.phone}</h5>
            </Col>
            <Col>
              <span className="text-secondary">Position</span>
              <h5>{props.deal.position}</h5>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <span className="text-secondary">Email</span>
              <h5>{props.deal.email}</h5>
            </Col>
            <Col>
              <span className="text-secondary">Entered Date</span>
              <h5>{moment.utc(props.deal.created_at).format("ll")}</h5>
            </Col>
            <Col>
              <span className="text-secondary">Product Name</span>
              <h5>{`${product.name}`}</h5>
            </Col>
            <Col>
              <span className="text-secondary">Amount</span>
              {product.type === "Investment" ? (
                <h5>
                  {formatAmount(props.deal.investment_amount, product.type)}
                </h5>
              ) : (
                <h5>{formatAmount(product.amount)}</h5>
              )}
            </Col>
          </Row>
          <Col>
            <span className="text-secondary">Remarks</span>
            <h5>{props.deal.remark}</h5>
          </Col>
        </Card.Body>
      </Card>
      <Card border="light" className="shadow-sm mt-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Deal Progress</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-4">
              <Form.Check
                type="checkbox"
                label="Introduction"
                name="introduction"
                // disabled={+introduction === 1 && true}
                value={introduction}
                checked={+introduction}
                onChange={() => setIntroduction(!introduction)}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Check
                type="checkbox"
                label="Meeting"
                name="meeting"
                // disabled={+introduction !== 0 && +meeting === 0 ? false : true}
                value={meeting}
                checked={+meeting}
                onChange={() => setMeeting(!meeting)}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Check
                type="checkbox"
                label="Inspection"
                name="inspection"
                // disabled={+meeting !== 0 && +inspection === 0 ? false : true}
                value={inspection}
                checked={+inspection}
                onChange={() => setInspection(!inspection)}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Check
                type="checkbox"
                label="Negotiation"
                name="negotiation"
                // disabled={
                //   +inspection !== 0 && +negotiation === 0 ? false : true
                // }
                value={negotiation}
                checked={+negotiation}
                onChange={() => setNegoitation(!negotiation)}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Check
                type="checkbox"
                label="Offer"
                // disabled={
                //   +negotiation !== 0 && +offer_toggle === 0 ? false : true
                // }
                name="offer_toggle"
                value={offer_toggle}
                checked={offer_toggle}
                onChange={() => setOffer_toggle(!offer_toggle)}
              />
            </Form.Group>
            {+meeting !== 0 && (
              <Form.Group className="mb-4">
                <Form.Label>Meeting Summary</Form.Label>

                <Form.Control
                  as="textarea"
                  placeholder="Enter meeting here"
                  name="meeting_summary"
                  value={meeting_summary}
                  onChange={(e) => setMeetingSummary(e.target.value)}
                  style={{ height: "100px" }}
                />
              </Form.Group>
            )}
            {offer_toggle && (
              <Form.Group className="mb-4">
                <Form.Label>Offer</Form.Label>
                <Form.Control
                  type="text"
                  name="offer"
                  value={offer}
                  placeholder="Enter Offer"
                  onChange={(e) => setOffer(e.target.value)}
                />
              </Form.Group>
            )}
            {transaction && (
              <Form.Group className="mb-4">
                <Form.Label>Payment</Form.Label>
                <Form.Select
                  name="payment"
                  value={payment}
                  onChange={(e) => setPayment(e.target.value)}
                >
                  <option value="Paid">Paid</option>
                  <option value="Not Paid">Not Paid</option>
                </Form.Select>
              </Form.Group>
            )}

            <Button type="submit">
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                  />
                  <span className="text-capitalize"> Submitting</span>
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default Transactions;
