import { Button, Spinner } from "@themesberg/react-bootstrap";
import * as FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { exportToExcel } from "../actions/dealActions";

export const ExportToExcel = ({ apiData, fileName }) => {
  // const fileType =
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // const fileExtension = ".xlsx";

  // const fileType = "text/csv;charset=utf-8";
  // const fileExtension = ".csv";


  // const exportToCSV = (apiData, fileName = "data") => {
  //   const ws = XLSX.utils.json_to_sheet(apiData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };

const exportToCSV = (apiData, fileName = "data") => {
  const ws = XLSX.utils.json_to_sheet(apiData);
  const csvBuffer = XLSX.utils.sheet_to_csv(ws);
  const data = new Blob([csvBuffer], { type: "text/csv" });
  FileSaver.saveAs(data, fileName + ".csv");
};

  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const { excel } = useSelector((state) => state.deal);

  useEffect(() => {
    if (excel) {
      // Update the exportToCSV function call with the dynamic fileName
      exportToCSV(excel, fileName);
    }
    setisLoading(false);
  }, [excel, fileName]);

  const getExportData = () => {
    dispatch(exportToExcel());
    setisLoading(true);
  };

  return (
    <Button
      onClick={(e) => exportToCSV(apiData, fileName)}
      // onClick={() => {
      //   alert('downloading...')
      // }}
      className='leads-download-button'
      disabled={isLoading}
    >
      {isLoading ? (
        <>
          <Spinner animation='border' size='sm' role='status' />
          <span className='text-capitalize'> Exporting...</span>
        </>
      ) : (
        "Export"
      )}
    </Button>
  );
};
