import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect, useSelector, useDispatch } from "react-redux";
import {
  addDepartment,
  updateDepartment,
} from "../../../actions/departmentActions";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Alert from "../../../components/Alert";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "@themesberg/react-bootstrap";

const DepartmentsModal = ({
  department: { loading },
  addDepartment,
  show,
  onHide,
}) => {
  const [department, setDepartment] = React.useState({
    name: "",
  });

  const dispatch = useDispatch();

  const { current } = useSelector((state) => state.department);

  useEffect(() => {
    if (current !== null) {
      setDepartment({ name: current.name });
    }
  }, [current]);

  const { name } = department;

  const onSubmit = (e) => {
    e.preventDefault();

    if (current !== null) {
      department.id = current.id;
      dispatch(updateDepartment(department, onHide));
    } else {
      addDepartment(department, onHide);
    }

    // clear form
    setDepartment({
      name: "",
    });
  };

  const onChange = (e) =>
    setDepartment({ ...department, [e.target.name]: e.target.value });

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {current ? "Update Department" : "Add Department"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='name'
                defaultValue={name}
                placeholder='Enter Department Name'
                onChange={onChange}
              />
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='text-white text-uppercase btn-block'
            variant='primary'
            disabled={loading || name === "" ? true : false}
            type='submit'
          >
            {loading ? (
              <>
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                ></Spinner>
                <span className='text-capitalize'> Submitting</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

DepartmentsModal.propTypes = {
  loading: PropTypes.object.isRequired,
  addDepartment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  department: state.department,
});
export default connect(mapStateToProps, { addDepartment })(DepartmentsModal);
