import React, { useState, useRef } from "react";
import { Button, Container, Spinner } from "react-bootstrap";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Select from "react-select";
import "./AdvancedFilter.css";
import { useSelector } from "react-redux";

const AdvancedFilter = ({
  filterTypeRef,
  setFilterType,
  setFilterTypeRef,
  dateFrom,
  dateTo,
  filterByDateAndType,
  clearFilter,
}) => {
  const [filterLoading, setFilterLoading] = useState(false);

  let values = useRef([
    new DateObject().subtract(3, "days"),
    new DateObject().add(3, "days"),
  ]);
  let formattedDate;

  const onUpdateDateRange = () => {
    // Convert DateObject back to JS Date Obj
    // values.current.forEach((value, index) => {
    //   if (value instanceof DateObject)
    // });

    dateFrom.current = values.current[0];
    dateTo.current = values.current[1];
  };

  const onClearFilter = () => {
    clearFilter();
  };

  const { loading } = useSelector((state) => state.deal);

  const onSubmit = () => {
    filterByDateAndType();
  };

  const options = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "personal",
      label: "Personal",
    },
    {
      value: "reassignedTo",
      label: "Re-Assigned To",
    },
  ];

  return (
    <Container fluid>
      <h6>Filter User Deals:</h6>
      <div className='filter__menu d-flex gap-2 flex-column flex-md-row'>
        <div style={{ width: "20rem" }}>
          <Select
            placeholder='Select Filter Type...'
            ref={filterTypeRef}
            options={options}
            onChange={(value) => (filterTypeRef.current = value.value)}
          />
        </div>
        <div>
          <DatePicker
            value={values}
            onChange={(value) => {
              // console.log(value);
              values.current = value;
              onUpdateDateRange(value);
            }}
            range
            plugins={[<DatePanel />]}
            placeholder='Select Date Range...'
          />
        </div>
        <div>
          <div className='d-flex gap-2 align-items-center'>
            <Button
              onClick={onSubmit}
              className='shadow-none border-0  px-4 text-uppercase'
              disabled={filterTypeRef === null ? true : false}
              style={{ width: "10rem", padding: ".6rem 1.5rem" }}
            >
              {loading ? <Spinner animation='border' size='sm' /> : "Submit"}
            </Button>
            <Button
              onClick={onClearFilter}
              variant='danger'
              className='shadow-none border-0  px-4 text-uppercase'
              style={{ width: "10rem", padding: ".6rem 1.5rem" }}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AdvancedFilter;
