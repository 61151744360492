import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";

import { Link } from "react-router-dom";

import {
    faEdit,
    faEllipsisH,
    faEye
} from "@fortawesome/free-solid-svg-icons";
import {
    Badge, Button,
    ButtonGroup
} from "@themesberg/react-bootstrap";

import Dropdown from "react-bootstrap/Dropdown";


import moment from "moment-timezone";
import "./Users.css";

const TableRow = (props) => {
  const { id, firstname, lastname, phone, email, position, deals, created_at } =
    props;

  const onVerifyDelete = (id) => {
    swal({
      title: "Delete Role",
      text: "Do you want to delete? ",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((result) => {
      if (result) {
        // props.dispatch(deleteUser(id));
      }
    });
  };

  return (
    <tr>
      <td>
        <span className='fw-normal'>{firstname}</span>
      </td>
      <td>
        <span className='fw-normal'>{lastname}</span>
      </td>
      <td>
        <span className='fw-normal'>{phone}</span>
      </td>
      <td>
        <span className='fw-normal'>{email}</span>
      </td>
      <td>
        <span className='fw-normal'>{position}</span>
      </td>
      <td>
        {props.role === null ? (
          <Badge bg='danger'>Not Active</Badge>
        ) : (
          <Badge bg='success'>Active</Badge>
        )}
      </td>
      <td>
        <span className='fw-normal'>{deals && deals}</span>
      </td>
      <td>
        <span className='fw-normal'>{moment.utc(created_at).format("ll")}</span>
      </td>

      <td>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            as={Button}
            split
            variant='link'
            className='text-dark m-0 p-0'
          >
            <span className='icon icon-sm'>
              <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`${id}`}>
              <FontAwesomeIcon icon={faEye} className='me-2' /> View
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                props.setModalShow(true);
                props.setUpdate(true);
                props.setUser(props);
              }}
            >
              <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

export default TableRow;
