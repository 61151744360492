import {
  LOADING_PRODUCT,
  GET_PRODUCTS,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  FILTER_PRODUCT,
  PRODUCT_ERROR,
} from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { apiEndpoint } from "../Global";
import toast from "react-hot-toast";

// Load products
export const getProducts = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_PRODUCT });
    const res = await axios.get(`${apiEndpoint}/products`);

    dispatch({
      type: GET_PRODUCTS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: PRODUCT_ERROR,
      payload: err.response.data,
    });
  }
};

// Create Product
export const createProduct = (product) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_PRODUCT });
    const res = await axios.post(
      `${apiEndpoint}/products/create`,
      product,
      config
    );

    dispatch({
      type: ADD_PRODUCT,
      payload: res.data.data,
    });
    toast.success(res.data.message);
    product.props.onHide();
  } catch (err) {
    dispatch({
      type: PRODUCT_ERROR,
      payload: err.response.data,
    });
  }
};

// UPDATE PRODUCT
export const updateProduct = (product) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_PRODUCT });
    const res = await axios.put(
      `${apiEndpoint}/products/update/${product.id}`,
      product,
      config
    );

    dispatch({
      type: UPDATE_PRODUCT,
      payload: res.data.data,
    });
    toast.success(res.data.message);
    product.props.onHide();
  } catch (err) {
    dispatch({
      type: PRODUCT_ERROR,
      payload: err.response.data,
    });
  }
};

// FILTER PRODUCT
export const filteredProduct = (search) => (dispatch) =>
  dispatch({ type: FILTER_PRODUCT, payload: search });

// DELETE PRODUCT
export const deleteProduct = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_PRODUCT });
    const res = await axios.delete(`${apiEndpoint}/products/delete/${id}`);

    dispatch({
      type: DELETE_PRODUCT,
      payload: id,
    });
    toast.success(res.data.message);
  } catch (err) {
    dispatch({
      type: PRODUCT_ERROR,
      payload: err.response.data,
    });
  }
};
