import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "react-lottie";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";

import {
  faCheck,
  faCog,
  faEllipsisH,
  faEdit,
  faTrashAlt,
  faEye,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Modal,
  Card,
  Table,
  Spinner,
  Nav,
  Pagination,
} from "@themesberg/react-bootstrap";
import { formatAmount } from "../Global";
import emptyProduct from "../assets/syntax-themes/93134-not-found";

import {
  getLeads,
  getUserLeads,
  createLead,
  updateLead,
  deleteLead,
  filteredLead,
} from "../actions/leadActions";
import { getProducts } from "../actions/productActions";
import moment from "moment-timezone";

const Leads = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [lead, setLead] = React.useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  React.useEffect(() => {
    (user && user.role && user.role.name === "Admin") ||
    user.role.name === "MD" ||
    user.role.name === "Supervisor"
      ? dispatch(getLeads())
      : dispatch(getUserLeads());

    dispatch(getProducts());
  }, [dispatch, user]);

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} /> Dashboard
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Leads</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Leads</h4>
          <p className='mb-0'>List of all our potential customer.</p>
        </div>
        <div className='btn-toolbar mb-2 mb-md-0'>
          <ButtonGroup>
            <Button variant='outline-primary' size='sm'>
              Share
            </Button>
            <Button variant='outline-primary' size='sm'>
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className='table-settings mb-4'>
        <Row className='justify-content-between align-items-center'>
          <Col xs={6} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Search'
                onChange={(e) => dispatch(filteredLead(e.target.value))}
              />
            </InputGroup>
          </Col>

          <Col xs={5} md={2} xl={2}>
            <Button
              variant='primary'
              type='button'
              className='w-100 text-uppercase'
              onClick={() => {
                setModalShow(true);
                setUpdate(false);
              }}
            >
              Add Lead
            </Button>
          </Col>
        </Row>
      </div>

      <LeadTable
        setModalShow={setModalShow}
        setUpdate={setUpdate}
        setLead={setLead}
        dispatch={dispatch}
      />
      <LeadModal
        dispatch={dispatch}
        show={modalShow}
        update={update}
        lead={lead}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Leads;

const LeadModal = (props) => {
  const [lead, setLead] = React.useState({
    productId: "",
    customer: "",
    phone: "",
    company: "",
    position: "",
    email: "",
  });
  const { loading } = useSelector((state) => state.lead);
  const { products } = useSelector((state) => state.product);
  const { user } = useSelector((state) => state.auth);

  const { customer, phone, company, position, email, productId } = lead;

  const onSubmit = (e) => {
    e.preventDefault();
    props.update
      ? props.dispatch(
          updateLead({
            id: props.lead.id,
            productId,
            userId: user.id,
            customer,
            phone,
            company,
            position,
            email,
            props,
          })
        )
      : props.dispatch(
          createLead({
            productId,
            userId: user.id,
            customer,
            phone,
            company,
            position,
            email,
            props,
          })
        );
  };

  React.useEffect(() => {
    setLead({
      productId: props.lead && props.lead.productId,
      customer: props.lead && props.lead.customer,
      phone: props.lead && props.lead.phone,
      company: props.lead && props.lead.company,
      position: props.lead && props.lead.position,
      email: props.lead && props.lead.email,
    });
  }, [props]);

  const onChange = (e) => setLead({ ...lead, [e.target.name]: e.target.value });

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {`${props.update ? "Update" : "Add"} Lead`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-4'>
            <Form.Select name='productId' value={productId} onChange={onChange}>
              <option value=''>Choose a Product</option>
              {products.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='customer'
                value={customer}
                placeholder='Enter Customer Name'
                onChange={onChange}
                required
              />
              <Form.Control.Feedback type='invalid'>
                This field is required
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='phone'
                value={phone}
                maxLength={11}
                placeholder='Enter Customer Phone'
                onChange={onChange}
                required
              />
              <Form.Control.Feedback type='invalid'>
                This field is required
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='email'
                name='email'
                value={email}
                placeholder='Enter Email Address'
                onChange={onChange}
                required
              />
              <Form.Control.Feedback type='invalid'>
                This field is required
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='company'
                value={company}
                placeholder='Enter Customer Company'
                onChange={onChange}
                required
              />
              <Form.Control.Feedback type='invalid'>
                This field is required
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='position'
                value={position}
                placeholder='Enter Customer Position'
                onChange={onChange}
              />
              <Form.Control.Feedback type='invalid'>
                This field is required
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='text-white text-uppercase btn-block'
            variant='primary'
            type='submit'
          >
            {loading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' />
                <span className='text-capitalize'> Submitting</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const LeadTable = (props) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [leadPerPage] = React.useState(10);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyProduct,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { filtered, leads } = useSelector((state) => state.lead);

  const paginate = ({ selected }) => setCurrentPage(selected);

  const indexOfLastLead = currentPage * leadPerPage;
  const currentLeads = filtered
    ? filtered && filtered.slice(indexOfLastLead, indexOfLastLead + leadPerPage)
    : leads && leads.slice(indexOfLastLead, indexOfLastLead + leadPerPage);

  const pageCount = Math.ceil(
    filtered
      ? filtered && filtered.length / leadPerPage
      : leads && leads.length / leadPerPage
  );

  const TableRow = (props) => {
    const { customer, phone, company, position, location, email, created_at } =
      props;

    const onVerifyDelete = (id) => {
      swal({
        title: "Delete Lead",
        text: "Do you want to delete? ",
        icon: "warning",
        buttons: ["Cancel", true],
        dangerMode: true,
      }).then((result) => {
        if (result) {
          props.dispatch(deleteLead(id));
        }
      });
    };

    return (
      <tr>
        <td>
          <span className='fw-normal'>{customer}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        <td>
          <span className='fw-normal'>{company}</span>
        </td>
        <td>
          <span className='fw-normal'>{position}</span>
        </td>
        <td>
          <span className='fw-normal'>{location}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>
            {moment.utc(created_at).format("ll")}
          </span>
        </td>

        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant='link'
              className='text-dark m-0 p-0'
            >
              <span className='icon icon-sm'>
                <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  props.setModalShow(true);
                  props.setUpdate(true);
                  props.setLead(props);
                }}
              >
                <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className='text-danger'
                onClick={() => onVerifyDelete(props.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {currentLeads.length > 0 ? (
        <>
          <Card
            border='light'
            className='table-wrapper table-responsive shadow-sm'
          >
            <Card.Body className='pt-0'>
              <Table hover className='user-table align-items-center'>
                <thead>
                  <tr>
                    <th className='border-bottom'>Customer</th>
                    <th className='border-bottom'>Phone</th>
                    <th className='border-bottom'>Company</th>
                    <th className='border-bottom'>Position</th>
                    <th className='border-bottom'>Location</th>
                    <th className='border-bottom'>Email</th>
                    <th className='border-bottom'>Created Date</th>
                    <th className='border-bottom'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentLeads.map((lead) => (
                    <TableRow
                      key={lead.id}
                      {...lead}
                      setModalShow={props.setModalShow}
                      setUpdate={props.setUpdate}
                      setLead={props.setLead}
                      dispatch={props.dispatch}
                    />
                  ))}
                </tbody>
              </Table>
              <div className='my-3' />
              {/* <Card.Footer className="border-0 d-lg-flex align-items-center justify-content-between"></Card.Footer> */}
            </Card.Body>
            <div className='paginate_container mx-auto'>
              <ReactPaginate
                previousLabel='Prev'
                nextLabel='Next'
                pageCount={pageCount}
                onPageChange={paginate}
                containerClassName={"paginationBtns"}
                previousLinkClassName={"previousBtn"}
                nextLinkClassName={"nextBtn"}
                disabledClassName={"disabledBtn"}
                activeClassName={"activeBtn"}
                pageRangeDisplayed={0}
                marginPagesDisplayed={0}
              />
            </div>
          </Card>
        </>
      ) : (
        <Lottie options={defaultOptions} height={300} width={300} />
      )}
    </>
  );
};
