import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState} from "react";
import { Button, Col, Form, InputGroup, Badge, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import DataTables from "../pages/DataTable/DataTable";
import moment from "moment-timezone";

 
const Residences = () => {
  const dispatch = useDispatch();

  // const { currentUserDeals } = useSelector((state) => state.deal);
  const [refreshLoading, setRefreshLoading] = useState(false);

   const columns = [
     {
       name: "Property",
       selector: (row) => row.property.name,
       style: {
         fontWeight: "bold",
       },
     },
     {
       name: "Location",
       selector: (row) => row.location,
     },
     {
       name: "Amount",
       selector: (row) => row.amount,
       // sortable: true,
     },
     {
       name: "Availability",
       cell: (row) => (
         <Badge bg={row.status === 'booked' ? "warning" : "success"}>
           {row.status === 'booked' ? "Closed" : "Active"}
         </Badge>
       ),
     },
     {
       name: "Created At",
       selector: (row) => moment.utc(row.created_at).format("ll"),
       sortable: true,
     },
     {
       name: "Updated At",
       selector: (row) => moment.utc(row.updated_at).format("ll"),
       sortable: true,
     },
   ];


  // useEffect(() => {
  //   dispatch(getProducts());
  // }, [dispatch]);

  return (
    <>
      <div className='table-settings mb-4'>
        <Row className='justify-content-between align-items-centger'>
          <Col xs={6} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Search'
                // onChange={(e) => dispatch(filteredProduct(e.target.value))}
              />
            </InputGroup>
          </Col>
          <Col xs={5} md={2} xl={2}>
            <Button
              variant='primary'
              type='button'
              className='w-100 text-uppercase'
              // onClick={() => {
              //   setModalShow(true);
              //   setUpdate(false);
              // }}
            >
              Add Residence
            </Button>
          </Col>
        </Row>
      </div>
      <DataTables
        title={"Residences"}
        // data={currentUserDeals}
        // data={currentUserDeals}
        data={[]}
        columns={columns}
        progressPending={refreshLoading}
        className='mt-3 mb-5'
      />
      {/* <ResidenceTable
        setModalShow={setModalShow}
        setUpdate={setUpdate}
        setProduct={setProduct}
        dispatch={dispatch}
      /> */}

      {/* <ResidenceModal
        dispatch={dispatch}
        show={modalShow}
        update={update}
        product={product}
        onHide={() => setModalShow(false)}
      /> */}
    </>
  );
};

export default Residences;
