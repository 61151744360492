import {
  LOADING_PRODUCT,
  GET_PRODUCTS,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  FILTER_PRODUCT,
  DELETE_PRODUCT,
  PRODUCT_ERROR,
} from "../actions/types";

const INITIALSTATE = {
  products: [],
  filtered: null,
  loading: false,
  error: null,
};

const productReducer = (state = INITIALSTATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: payload,
        loading: false,
      };
    case ADD_PRODUCT:
      return {
        ...state,
        products: [payload, ...state.products],
        loading: false,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === payload.id ? payload : product
        ),
        loading: false,
      };
    case FILTER_PRODUCT:
      return {
        ...state,
        filtered: state.products.filter((product) => {
          const regex = new RegExp(`${payload}`, "gi");
          return product.name.match(regex) || product.amount.match(regex);
        }),
        loading: false,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter((product) => product.id !== payload),
        loading: false,
      };
    case PRODUCT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default productReducer;
