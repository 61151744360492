export const GET_CONTACTS = "GET_CONTACTS";
export const ADD_CONTACT = "ADD_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const SET_CURRENT = "SET_CURRENT";
export const SET_LOADING = "SET_LOADING";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CLEAR_CONTACTS = "CLEAR_CONTACTS";
export const FILTER_CONTACTS = "FILTER_CONTACTS";
export const SET_AVAILABLE = "SET_AVAILABLE";
export const GET_AVAILABLE = "GET_AVAILABLE";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const CLEAR_FILTER = "CLEAR_FILTER";

export const GET_AMENITIES = "GET_AMENITIES";
export const DELETE_AMENITY = "DELETE_AMENITY";
export const UPDATE_AMENITY = "UPDATE_AMENITY";
export const UP = "EDIT_AMENITY";
export const ADD_AMENITY = "ADD_AMENITY";
export const AMENITY_ERROR = "AMENITIES_ERROR";

export const LOADING_ACTIVITY = "LOADING_ACTIVITY";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const ACTIVITY_ERROR = "ACTIVITY_ERROR";

export const LOADING_PRODUCT = "LOADING_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const FILTER_PRODUCT = "FILTER_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const PRODUCT_ERROR = "PRODUCT_ERROR";

export const LOADING_LOCATION = "LOADING_LOCATION";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATION_DETAILS = "GET_LOCATION_DETAILS";
export const FILTER_LOCATION = "FILTER_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const ADD_LOCATION = "ADD_LOCATION";
export const LOCATION_ERROR = "LOCATION_ERROR";

export const LOADING_DEAL = "LOADING_DEAL";
export const GET_DEALS = "GET_DEALS";
export const EXPORT_DEALS = "EXPORT_DEALS";
export const GET_DASHBOARD_DETAILS = "GET_DASHBOARD_DETAILS";
export const FILTER_DEAL = "FILTER_DEAL";
export const DELETE_DEAL = "DELETE_DEAL";
export const UPDATE_DEAL = "UPDATE_DEAL";
export const GET_USER_DEALS = "GET_USER_DEALS";
export const ADD_DEAL = "ADD_DEAL";
export const DEAL_ERROR = "DEAL_ERROR";

export const LOADING_ROLE = "LOADING_ROLE";
export const GET_ROLES = "GET_ROLES";
export const FILTER_ROLE = "FILTER_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const ADD_ROLE = "ADD_ROLE";
export const ROLE_ERROR = "ROLE_ERROR";

export const LOADING_LEAD = "LOADING_LEAD";
export const GET_LEADS = "GET_LEADS";
export const GET_USER_LEADS = "GET_USER_LEADS";
export const FILTER_LEAD = "FILTER_LEAD";
export const DELETE_LEAD = "DELETE_LEAD";
export const UPDATE_LEAD = "UPDATE_LEAD";
export const ADD_LEAD = "ADD_LEAD";
export const LEAD_ERROR = "LEAD_ERROR";

export const LOADING_TRANSACTION = "LOADING_TRANSACTION";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_USER_TRANSACTIONS = "GET_USER_TRANSACTIONS";
export const FILTER_TRANSACTION = "FILTER_TRANSACTION";
export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const CLEAR_TRANSACTION = "CLEAR_TRANSACTION";
export const TRANSACTION_ERROR = "TRANSACTION_ERROR";

export const LOADING_DEPARTMENT = "LOADING_DEPARTMENT";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const FILTER_DEPARTMENTS = "FILTER_DEPARTMENTS";
export const DEPARTMENTS_ERROR = "DEPARTMENTS_ERROR";

export const LOADING_TEAMS = "LOADING_TEAMS";
export const GET_TEAMS = "GET_TEAMS";
export const DELETE_TEAM = "DELETE_TEAM";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const ADD_TEAM = "ADD_TEAM";
export const FILTER_TEAMS = "FILTER_TEAMS";
export const TEAMS_ERROR = "TEAMS_ERROR";

export const ADD_GALLERY = "ADD_GALLERY";
export const DELETE_GALLERY = "DELETE_GALLERY";
export const GALLERY_ERROR = "GALLERY_ERROR";

export const GET_PROPERTIES = "GET_PROPERTIES";
export const DELETE_PROPERTY = "DELETE_PROPERTY";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const EDIT_PROPERTY = "EDIT_PROPERTY";
export const ADD_PROPERTY = "ADD_PROPERTY";
export const PROPERTY_ERROR = "PROPERTY_ERROR";
export const PROPERTY_AMENITY_ASSOC = "PROPERTY_AMENITY_ASSOC";

export const UPDATE_USER = "UPDATE_USER";

export const GET_VISITORS = "GET_VISTORS";
export const VISITOR_ERROR = "VISITOR_ERROR";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const INACTIVE_USERS_LOADED = "INACTIVE_USERS_LOADED";
export const USERS_LOADED = "USERS_LOADED";
export const GET_SALES_USERS = "GET_SALES_USERS";
export const GET_SUPERVISORS = "GET_SUPERVISORS";
export const FILTER_USER = "FILTER_USER";
export const FILTER_INACTIVE_USER = "FILTER_INACTIVE_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const VERIFY_PHONE = "VERIFY_PHONE";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_PHONE_TEST = "VERIFY_PHONE_TEST";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const LOADING_RECORDS = "LOADING_RECORD";
export const GET_RECORDS = "GET_RECORDS";
export const EXPORT_RECORDS = "EXPORT_RECORDS";
export const FILTER_RECORD = "FILTER_RECORD";
export const DELETE_RECORD = "DELETE_RECORD";
export const UPDATE_RECORD = "UPDATE_RECORD";
export const ADD_RECORD = "ADD_RECORD";
export const RECORD_ERROR = "RECORD_ERROR";
export const RECORD_TYPES = "RECORD_TYPES";

export const ADD_RECORDTYPE = "ADD_RECORDTYPE";
export const GET_RECORDTYPE = "GET_RECORDTYPE";
export const FILTER_RECORDTYPE = "FILTER_RECORDTYPE";
export const UPDATE_RECORDTYPE = "UPDATE_RECORDTYPE";
export const DELETE_RECORDTYPE = "DELETE_RECORDTYPE";
export const RECORDTYPE_ERROR = "RECORDTYPE_ERROR";
