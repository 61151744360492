import {
  LOADING_DEAL,
  GET_DEALS,
  EXPORT_DEALS,
  GET_DASHBOARD_DETAILS,
  UPDATE_DEAL,
  DELETE_DEAL,
  FILTER_DEAL,
  DEAL_ERROR,
  GET_USER_DEALS,
} from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { apiEndpoint } from "../Global";
import toast from "react-hot-toast";

// Load Deals
export const getDeals = (pageNumber) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    // dispatch({ type: LOADING_DEAL });
    const res = await axios.get(`${apiEndpoint}/deals?page=${pageNumber + 1}`);

    dispatch({
      type: GET_DEALS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      payload: err.response.data,
    });
  }
};

// Load Deals Count
export const getDealsCounts = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    // dispatch({ type: LOADING_DEAL });
    const res = await axios.get(`${apiEndpoint}/deals/dashboard`);

    dispatch({
      type: GET_DASHBOARD_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      payload: err.response.data,
    });
  }
};

// Get User Deals
export const getUserDealsFilter = (filter) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_DEAL });
    const res = await axios.post(
      `${apiEndpoint}/deals/filteruserdeals`,
      filter
    );
    // console.log(res.data.userDeals);
    dispatch({
      type: GET_USER_DEALS,
      payload: res.data.userDeals,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: DEAL_ERROR,
      payload: err.response.data,
    });
  }
};

// Load Filtered Deals
export const getFilteredDeals = (filter) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_DEAL });
    const res = await axios.post(`${apiEndpoint}/deals/dealfilter`, filter);

    dispatch({
      type: GET_DASHBOARD_DETAILS,
      payload: res.data,
    });
    filter.setModalShow(false);
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      payload: err.response.data,
    });
  }
};

// Filtered Type Open
export const filteredDealByTypeOpen = (value) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_DEAL });
    const res = await axios.post(
      `${apiEndpoint}/deals/filterbytypeopen?page=${value.num + 1}`,
      value
    );

    dispatch({
      type: GET_DEALS,
      payload: res.data,
      status: "Open",
    });
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      // payload: err.response.data,
    });
  }
};

// Filtered Type Closed
export const filteredDealByTypeClosed = (value) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_DEAL });
    const res = await axios.post(
      `${apiEndpoint}/deals/filterbytypeclosed?page=${value.num + 1}`,
      value
    );

    dispatch({
      type: GET_DEALS,
      payload: res.data,
      status: "Closed",
    });
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      payload: err.response.data,
    });
  }
};

// Load Account Deals
export const getUserDeals = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_DEAL });
    const res = await axios.get(`${apiEndpoint}/deals/me`);

    dispatch({
      type: GET_DEALS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      // payload: err.response.data,
    });
  }
};

// Create Deal
export const createDeal = (deal) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_DEAL });
    const res = await axios.post(`${apiEndpoint}/deals/create`, deal, config);

    deal.onHide();
    dispatch(getDeals());
    // dispatch({
    //   type: ADD_DEAL,
    //   payload: res.data.data,
    // });
    toast.success(res.data.message);
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      // payload: err.response.data,
    });
    toast.error(err.response.data.message);
  }
};

// UPDATE DEAL
export const updateDeal = (deal) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_DEAL });
    const res = await axios.put(
      `${apiEndpoint}/deals/update/${deal.id}`,
      deal,
      config
    );

    dispatch({
      type: UPDATE_DEAL,
      // payload: res.data.data,
    });
    toast.success(res.data.message);
    deal.onHide();
    dispatch(getDeals());
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      // payload: err.response.data.message,
    });
  }
};

export const filterByStatus = (customer) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_DEAL });
    const res = await axios.post(`${apiEndpoint}/deals/filterbytype`, customer);

    dispatch({
      type: FILTER_DEAL,
      payload: res.data.data,
    });

    // dispatch(getDeals());
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      // payload: err.response.data.message,
    });
  }
};

export const exportToExcel = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`${apiEndpoint}/deals/exporttoexcel`);

    dispatch({
      type: EXPORT_DEALS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: DEAL_ERROR,
      // payload: err.response.data.message,
    });
  }
};

// FILTER DEAL
export const filteredDeal = (search) => (dispatch) =>
  dispatch({ type: FILTER_DEAL, payload: search });

// DELETE DEAL
export const deleteDeal = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_DEAL });
    const res = await axios.delete(`${apiEndpoint}/deals/delete/${id}`);

    dispatch({
      type: DELETE_DEAL,
      payload: id,
    });
    toast.success(res.data.message);
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      payload: err.response.data,
    });
  }
};
