import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import { ProfileCardWidget } from "../components/Widgets";
import { connect, useSelector, useDispatch } from "react-redux";
import { updateUser } from "../actions/authActions";

import moment from "moment-timezone";

const Settings = ({ auth: { loading, user } }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);

  const [userProfile, setUserProfile] = useState({
    id: user.id,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    phone: user.phone,
    position: user.position,
  });

  const onChange = (e) =>
    setUserProfile({ ...userProfile, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUser(userProfile));
  };

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'></div>

      <Row>
        <Col xs={12} xl={8}>
          <Card border='light' className='bg-white shadow-sm mb-4'>
            <Card.Body>
              <h5 className='mb-4'>Personal information</h5>
              <Form onSubmit={onSubmit}>
                <Row>
                  <Col md={6} className='mb-3'>
                    <Form.Group id='firstName'>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type='text'
                        name='firstname'
                        defaultValue={user.firstname}
                        onChange={onChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className='mb-3'>
                    <Form.Group id='lastName'>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type='text'
                        name='lastname'
                        defaultValue={user.lastname}
                        onChange={onChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='align-items-center'></Row>
                <Row>
                  <Col md={6} className='mb-3'>
                    <Form.Group id='email'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type='email'
                        name='email'
                        defaultValue={user.email}
                        onChange={onChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className='mb-3'>
                    <Form.Group id='phone'>
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type='text'
                        name='phone'
                        maxLength={11}
                        defaultValue={user.phone}
                        onChange={onChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className='mb-3'>
                    <Form.Group id='emal'>
                      <Form.Label>Position</Form.Label>
                      <Form.Control
                        type='text'
                        name='position'
                        defaultValue={user.position}
                        onChange={onChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className='mb-3'>
                    <Form.Group id='position'>
                      <Form.Label>Last Updated</Form.Label>
                      <Form.Control
                        type='text'
                        disabled
                        style={{ cursor: "not-allowed" }}
                        defaultValue={moment.utc(user.updated_at).format("ll")}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className='mt-3'>
                  <Button
                    variant='primary'
                    type='submit'
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                        ></Spinner>
                        <span className='text-capitalize'>
                          {" "}
                          Updating Profile
                        </span>
                      </>
                    ) : (
                      "Update Profile"
                    )}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <ProfileCardWidget />
            </Col>
            {/* <Col xs={12}>
              <ChoosePhotoWidget
                title="Select profile photo"
                photo={Profile3}
              />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </>
  );
};

Settings.propTypes = {
  loading: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { updateUser })(Settings);
