import {
  AUTH_ERROR,
  CLEAR_ERRORS,
  FILTER_INACTIVE_USER,
  FILTER_USER,
  GET_SALES_USERS,
  GET_SUPERVISORS,
  INACTIVE_USERS_LOADED,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  RESET_PASSWORD,
  SET_LOADING,
  UPDATE_USER,
  UPDATE_USER_ROLE,
  USERS_LOADED,
  USER_LOADED,
  VERIFY_OTP,
  VERIFY_PHONE,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  user: null,
  users: [],
  inactiveUsers: [],
  supervisors: [],
  phoneExists: false,
  isValidOTP: false,
  error: null,
  totalRows: 0,
  filtered: null,
  filteredInactive: null,
  salesUsers: [],
  authLoader: false,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        user: payload,
        isAuthenticated: true,
        authLoader: false,
      };
    case GET_SALES_USERS:
      return {
        ...state,
        salesUsers: payload,
        authLoader: false,
      };
    case USERS_LOADED:
      return {
        ...state,
        users: payload,
        // totalRows: payload.total,
        authLoader: false,
      };
    case INACTIVE_USERS_LOADED:
      return {
        ...state,
        inactiveUsers: payload,
        // totalRows: payload.total,
        authLoader: false,
      };
    case GET_SUPERVISORS:
      return {
        ...state,
        supervisors: payload,
        authLoader: false,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        isAuthenticated: true,
        token: localStorage.getItem("token"),
        authLoader: false,
        error: null,
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === payload.id ? payload : user
        ),
        authLoader: false,
      };
    case UPDATE_USER_ROLE:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === payload.id ? payload : user
        ),
        authLoader: false,
      };
    case FILTER_USER:
      return {
        ...state,
        filtered: state.users.filter((user) => {
          const regex = new RegExp(`${payload}`, "gi");
          return (
            user.firstname.match(regex) ||
            user.lastname.match(regex) ||
            user.position.match(regex) ||
            user.email.match(regex) ||
            user.phone.match(regex)
          );
        }),
        authLoader: false,
      };
    case FILTER_INACTIVE_USER:
      return {
        ...state,
        filteredInactive: state.inactiveUsers.filter((user) => {
          const regex = new RegExp(`${payload}`, "gi");
          return (
            user.firstname.match(regex) ||
            user.lastname.match(regex) ||
            user.position.match(regex) ||
            user.email.match(regex) ||
            user.phone.match(regex)
          );
        }),
        authLoader: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case REGISTER_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        authLoader: false,
        error: payload,
      };
    case CLEAR_ERRORS:
    case VERIFY_PHONE:
      return {
        ...state,
        phoneExists: true,
        authLoader: false,
      };
    case VERIFY_OTP:
      return {
        ...state,
        isValidOTP: true,
        authLoader: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        phoneExists: false,
        isValidOTP: false,
        authLoader: false,
      };
    case SET_LOADING:
      return {
        ...state,
        authLoader: true,
      };
    default:
      return state;
  }
};

export default authReducer;
