import {
  LOADING_LOCATION,
  GET_LOCATIONS,
  GET_LOCATION_DETAILS,
  ADD_LOCATION,
  UPDATE_LOCATION,
  FILTER_LOCATION,
  DELETE_LOCATION,
  LOCATION_ERROR,
} from "../actions/types";

const INITIALSTATE = {
  locations: [],
  mma_de: null,
  mma1_arrival: null,
  evergreen: null,
  legend: null,
  mm2: null,
  others: null,
  transcorp_abuja: null,
  Imma_de: null,
  Imma1_arrival: null,
  Ievergreen: null,
  Ilegend: null,
  Imm2: null,
  Iothers: null,
  Itranscorp_abuja: null,
  current: false,
  filtered: null,
  loading: false,
  error: null,
};

const locationReducer = (state = INITIALSTATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_LOCATION:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATIONS:
      return {
        ...state,
        locations: payload.data,
        loading: false,
      };
    case GET_LOCATION_DETAILS:
      return {
        ...state,
        mma_de: payload.mma_de,
        mma1_arrival: payload.mma1_arrival,
        evergreen: payload.evergreen,
        legend: payload.legend,
        mm2: payload.mm2,
        others: payload.others,
        transcorp_abuja: payload.transcorp_abuja,
        Imma_de: payload.Imma_de,
        Imma1_arrival: payload.Imma1_arrival,
        Ievergreen: payload.Ievergreen,
        Ilegend: payload.Ilegend,
        Imm2: payload.Imm2,
        Itranscorp_abuja: payload.Itranscorp_abuja,
        Iothers: payload.Iothers,
      };
    case ADD_LOCATION:
      return {
        ...state,
        locations: [payload, ...state.locations],
        loading: false,
      };
    case UPDATE_LOCATION:
      return {
        ...state,
        locations: state.locations.map((location) =>
          location.id === payload.id ? payload : location
        ),
        loading: false,
      };
    case FILTER_LOCATION:
      return {
        ...state,
        filtered: state.locations.filter((location) => {
          const regex = new RegExp(`${payload}`, "gi");
          return location.name.match(regex);
        }),
        loading: false,
      };
    case DELETE_LOCATION:
      return {
        ...state,
        locations: state.locations.filter(
          (location) => location.id !== payload
        ),
        loading: false,
      };
    case LOCATION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default locationReducer;
