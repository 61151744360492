import {
  faBoxOpen,
  faBriefcase,
  faHome,
  faMapMarkerAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// pages
import Departments from "../Departments/Departments";
import Products from "../../Products";
import Roles from "../../Roles";
import Locations from "../Locations/Locations";
import RecordType from "../../RecordType";
import Residences from "../../Residences";

// components
import { Breadcrumb, Col, Nav, Row, Tab } from "@themesberg/react-bootstrap";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const Setup = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Setup</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Setup</h4>
          <p className='mb-0'>Products . Residences. Departments . Roles . Locations </p>
        </div>
      </div>
      <Tab.Container defaultActiveKey='products'>
        <Row>
          <Col lg={12}>
            <Nav fill variant='pills' className='flex-column flex-sm-row'>
              <Nav.Item>
                <Nav.Link eventKey='products' className='mb-sm-3 mb-md-0'>
                  <FontAwesomeIcon icon={faBoxOpen} className='me-2' /> Products
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='residences' className='mb-sm-3 mb-md-0'>
                  <FontAwesomeIcon icon={faBriefcase} className='me-2' />{" "}
                  Residences
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='departments' className='mb-sm-3 mb-md-0'>
                  <FontAwesomeIcon icon={faUsers} className='me-2' />{" "}
                  Departments
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='roles' className='mb-sm-3 mb-md-0'>
                  <FontAwesomeIcon icon={faBriefcase} className='me-2' /> Roles
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='locations' className='mb-sm-3 mb-md-0'>
                  <FontAwesomeIcon icon={faMapMarkerAlt} className='me-2' />{" "}
                  Locations
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="recordType" className="mb-sm-3 mb-md-0">
                  <FontAwesomeIcon icon={faBriefcase} className="me-2" />{" "}
                  Records
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey='products' className='py-4'>
                <Products />
              </Tab.Pane>
              <Tab.Pane eventKey='residences' className='py-4'>
                <Residences />
              </Tab.Pane>
              <Tab.Pane eventKey='departments' className='py-4'>
                <Departments />
              </Tab.Pane>
              <Tab.Pane eventKey='roles' className='py-4'>
                <Roles />
              </Tab.Pane>
              <Tab.Pane eventKey='locations' className='py-4'>
                <Locations />
              </Tab.Pane>
              <Tab.Pane eventKey='recordType' className='py-4'>
                <RecordType />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default Setup;
