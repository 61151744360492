import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "react-lottie";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";

import {
  faCheck,
  faCog,
  faEllipsisH,
  faEdit,
  faTrashAlt,
  faEye,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Modal,
  Card,
  Table,
  Spinner,
  Nav,
  Pagination,
} from "@themesberg/react-bootstrap";
import { formatAmount } from "../Global";
import emptyProduct from "../assets/syntax-themes/93134-not-found";

import {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  filteredProduct,
} from "../actions/productActions";
import moment from "moment-timezone";
import { ExportToExcel } from "./ExportDeals";

const Products = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [product, setProduct] = React.useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  return (
    <>
      <div className='table-settings mb-4'>
        <Row className='justify-content-between align-items-center'>
          <Col xs={6} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Search'
                onChange={(e) => dispatch(filteredProduct(e.target.value))}
              />
            </InputGroup>
          </Col>

          <Col xs={5} md={2} xl={2}>
            <Button
              variant='primary'
              type='button'
              className='w-100 text-uppercase'
              onClick={() => {
                setModalShow(true);
                setUpdate(false);
              }}
            >
              Add Product
            </Button>
          </Col>
        </Row>
      </div>

      <ProductTable
        setModalShow={setModalShow}
        setUpdate={setUpdate}
        setProduct={setProduct}
        dispatch={dispatch}
      />
      <ProductModal
        dispatch={dispatch}
        show={modalShow}
        update={update}
        product={product}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Products;

const ProductModal = (props) => {
  const [product, setProduct] = React.useState({
    name: "",
    amount: "",
    type: "",
  });
  const { loading } = useSelector((state) => state.product);

  const { name, amount, type } = product;

  const onSubmit = (e) => {
    e.preventDefault();
    props.update
      ? props.dispatch(
          updateProduct({ id: props.product.id, name, amount, type, props })
        )
      : props.dispatch(createProduct({ name, amount, type, props }));
  };

  React.useEffect(() => {
    setProduct({
      name: props.product && props.product.name,
      amount: props.product && props.product.amount,
      type: props.product && props.product.type,
    });
  }, [props]);

  const onChange = (e) =>
    setProduct({ ...product, [e.target.name]: e.target.value });

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {`${props.update ? "Update" : "Add"} Product`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Select
                autoFocus
                type='text'
                name='type'
                value={type}
                onChange={onChange}
              >
                <option value=''>Choose a Type</option>
                <option value='Property'>Property</option>
                <option value='Investment'>Investment</option>
              </Form.Select>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='name'
                value={name}
                placeholder='Enter Product Name'
                onChange={onChange}
              />
            </InputGroup>
          </Form.Group>
          {type === "Property" && (
            <Form.Group className='mb-4'>
              <InputGroup>
                <Form.Control
                  autoFocus
                  type='number'
                  name='amount'
                  value={amount}
                  placeholder='Enter Amount'
                  onChange={onChange}
                />
              </InputGroup>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='text-white text-uppercase btn-block'
            disabled={name && type ? false : true}
            variant='primary'
            type='submit'
          >
            {loading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' />
                <span className='text-capitalize'> Submitting</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const ProductTable = (props) => {
  const { products } = useSelector((state) => state.product);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [productPerPage] = React.useState(10);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyProduct,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { filtered } = useSelector((state) => state.product);

  const paginate = ({ selected }) => setCurrentPage(selected);

  const indexOfLastProduct = currentPage * productPerPage;
  const currentProducts = filtered
    ? filtered &&
      filtered.slice(indexOfLastProduct, indexOfLastProduct + productPerPage)
    : products &&
      products.slice(indexOfLastProduct, indexOfLastProduct + productPerPage);

  const pageCount = Math.ceil(
    filtered
      ? filtered && filtered.length / productPerPage
      : products && products.length / productPerPage
  );

  const TableRow = (props) => {
    const { name, amount, type, created_at } = props;

    const onVerifyDelete = (id) => {
      swal({
        title: "Delete Product",
        text: "Do you want to delete? ",
        icon: "warning",
        buttons: ["Cancel", true],
        dangerMode: true,
      }).then((result) => {
        if (result) {
          props.dispatch(deleteProduct(id));
        }
      });
    };

    return (
      <>
        <tr>
          <td>
            <span className='fw-normal'>{name}</span>
          </td>
          <td>
            {amount ? (
              <span className='fw-normal'>{formatAmount(amount)}</span>
            ) : (
              <span className='fw-normal'>--</span>
            )}
          </td>
          <td>
            <span className='fw-normal'>{type}</span>
          </td>
          <td>
            <span className='fw-normal'>
              {moment.utc(created_at).format("ll")}
            </span>
          </td>

          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                as={Button}
                split
                variant='link'
                className='text-dark m-0 p-0'
              >
                <span className='icon icon-sm'>
                  <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    props.setModalShow(true);
                    props.setUpdate(true);
                    props.setProduct(props);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit
                </Dropdown.Item>
                <Dropdown.Item
                  className='text-danger'
                  onClick={() => onVerifyDelete(props.id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Remove
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
        <ExportToExcel
          apiData={products}
          // fileName={`lead_${current_page}`}
        />
      </>
    );
  };

  return (
    <>
      {currentProducts.length > 0 ? (
        <>
          <Card
            border='light'
            className='table-wrapper table-responsive shadow-sm'
          >
            <Card.Body className='pt-0'>
              <Table hover className='user-table align-items-center'>
                <thead>
                  <tr>
                    <th className='border-bottom'>Name</th>
                    <th className='border-bottom'>Amount</th>
                    <th className='border-bottom'>Type</th>
                    <th className='border-bottom'>Created Date</th>
                    <th className='border-bottom'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentProducts.map((product) => (
                    <TableRow
                      key={product.id}
                      {...product}
                      setModalShow={props.setModalShow}
                      setUpdate={props.setUpdate}
                      setProduct={props.setProduct}
                      dispatch={props.dispatch}
                    />
                  ))}
                </tbody>
              </Table>
              <div className='my-3' />
              {/* <Card.Footer className="border-0 d-lg-flex align-items-center justify-content-between"></Card.Footer> */}
            </Card.Body>
            <div className='paginate_container mx-auto'>
              <ReactPaginate
                previousLabel='Prev'
                nextLabel='Next'
                pageCount={pageCount}
                onPageChange={paginate}
                containerClassName={"paginationBtns"}
                previousLinkClassName={"previousBtn"}
                nextLinkClassName={"nextBtn"}
                disabledClassName={"disabledBtn"}
                activeClassName={"activeBtn"}
              />
            </div>
          </Card>
        </>
      ) : (
        <Lottie options={defaultOptions} height={300} width={300} />
      )}
    </>
  );
};
