import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import emptyProduct from "../assets/syntax-themes/93134-not-found";
import TableRow from "./TableRow";

import { Card, Form, InputGroup, Table } from "@themesberg/react-bootstrap";
import { loadUsers } from "../actions/authActions";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { filteredUser } from "../actions/authActions";

import UserDealModal from "./UserDealModal";
import UserModal from "./UserModal";
import { ExportToExcel } from "./ExportDeals";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: emptyProduct,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ActiveUsers = ({ props }) => {
  const { users, filtered, totalRows } = useSelector((state) => state.auth);
  const [modalShow, setModalShow] = useState(false);
  const [dealModalShow, setDealModalShow] = useState(false);
  const [user, setUser] = useState(null);
  const [userDeals, setUserDeal] = useState(null);

  const [update, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [userPerPage] = useState(10);

  const dispatch = useDispatch();

  const [refreshLoading, setRefreshLoading] = useState(false);

  const paginate = ({ selected }) => setCurrentPage(selected);

  const indexOfLastUser = currentPage * userPerPage;
  const currentUsers = filtered
    ? filtered && filtered.slice(indexOfLastUser, indexOfLastUser + userPerPage)
    : users && users.slice(indexOfLastUser, indexOfLastUser + userPerPage);

  const pageCount = Math.ceil(
    filtered
      ? filtered && filtered.length / userPerPage
      : users && users.length / userPerPage
  );

  useEffect(() => {
    dispatch(loadUsers());
  }, [dispatch]);

  return (
    <>
      <Card.Body className='pt-0'>
        <div className='d-flex justify-content-between align-items-center pt-3 pb-5 px-2 w-100'>
          <h2 className='py-0 my-0'>Active Users</h2>
          {/* Search Box */}
          <div className='table-settings w-25'>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Search'
                onChange={(e) => dispatch(filteredUser(e.target.value))}
              />
            </InputGroup>
          </div>
        </div>

        <ExportToExcel
          apiData={currentUsers}
          // fileName={`lead_${current_page}`}
        />

        {currentUsers.length > 0 ? (
          <>
            <Table hover className='user-table align-items-center'>
              <thead>
                <tr>
                  <th className='border-bottom'>Firstname</th>
                  <th className='border-bottom'>Lastname</th>
                  <th className='border-bottom'>Phone</th>
                  <th className='border-bottom'>Email</th>
                  <th className='border-bottom' colSpan={1}>
                    Position
                  </th>
                  <th className='border-bottom'>Status</th>
                  <th className='border-bottom'>Deals</th>
                  <th className='border-bottom'>Created Date</th>
                  <th className='border-bottom'>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((role) => (
                  <TableRow
                    key={role.id}
                    {...role}
                    setModalShow={setModalShow}
                    setUpdate={setUpdate}
                    setUser={setUser}
                    dispatch={dispatch}
                    setUserDeal={setUserDeal}
                    setDealModalShow={setDealModalShow}
                  />
                ))}
              </tbody>
            </Table>
            <div className='my-3' />
          </>
        ) : (
          <Lottie options={defaultOptions} height={300} width={300} />
        )}
      </Card.Body>
      {currentUsers.length > 0 && (
        <div className='paginate_container mx-auto'>
          <ReactPaginate
            previousLabel='Prev'
            nextLabel='Next'
            pageCount={pageCount}
            onPageChange={paginate}
            containerClassName={"paginationBtns"}
            previousLinkClassName={"previousBtn"}
            nextLinkClassName={"nextBtn"}
            disabledClassName={"disabledBtn"}
            activeClassName={"activeBtn"}
          />
        </div>
      )}

      <UserModal
        dispatch={dispatch}
        show={modalShow}
        update={update}
        user={user}
        onHide={() => setModalShow(false)}
      />
      <UserDealModal
        show={dealModalShow}
        onHide={() => setDealModalShow(false)}
        userDeals={userDeals}
      />
    </>
  );
};

export default ActiveUsers;
