import React from "react";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHome, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Col, Row } from "@themesberg/react-bootstrap";

import ActiveUsers from "./ActiveUsers";
import InactiveUsers from "./InactiveUsers";

import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { Nav, Tab } from "@themesberg/react-bootstrap";
import { ExportToExcel } from "./ExportDeals";

import "./Users.css";

import { loadUsers } from "../actions/authActions";
import { getRoles } from "../actions/roleActions";
import "./Users.css";

const Users = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadUsers());
    dispatch(getRoles());
  }, [dispatch]);

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        {/* Breadcrumb */}
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} /> Dashboard
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FontAwesomeIcon icon={faUsers} /> Users
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      {/* Tab Groups */}
      <Tab.Container defaultActiveKey='products'>
        <Row>
          <Col lg={12}>
            <Nav fill variant='pills' className='flex-column flex-sm-row'>
              <Nav.Item>
                <Nav.Link eventKey='products' className='mb-sm-3 mb-md-0'>
                  <FontAwesomeIcon icon={faBoxOpen} className='me-2' /> Active
                  Users
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='departments' className='mb-sm-3 mb-md-0'>
                  <FontAwesomeIcon icon={faUsers} className='me-2' /> Inactive
                  Users
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey='products' className='py-4'>
                <ActiveUsers />
              </Tab.Pane>
              <Tab.Pane eventKey='departments' className='py-4'>
                <InactiveUsers />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      {/* <UserTable
        setModalShow={setModalShow}
        setDealModalShow={setDealModalShow}
        setUpdate={setUpdate}
        setUser={setUser}
        setUserDeal={setUserDeal}
        dispatch={dispatch}
      /> */}

      {/* <UserModal
        dispatch={dispatch}
        show={modalShow}
        update={update}
        user={user}
        onHide={() => setModalShow(false)}
      /> */}
      {/* <UserDealModal
        show={dealModalShow}
        onHide={() => setDealModalShow(false)}
        userDeals={userDeals}
      /> */}
    </>
  );
};

export default Users;
