import {
  faEdit,
  faEllipsisH,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, ButtonGroup, Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { BsArrow90DegLeft, BsThreeDots } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  deleteDeal,
  filteredDeal,
  getUserDealsFilter,
} from "../../actions/dealActions";
import DataTables from "../../pages/DataTable/DataTable";
import DealModal from "../../pages/DealModal";
import AdvancedFilter from "../AdvancedFilter/AdvancedFilter";
import "./UserDeals.css";
import { ExportToExcel } from "../../pages/ExportDeals";

const UserDeals = (props) => {
  const { users, salesUsers, user } = useSelector((state) => state.auth);

  let authUser = user;

  const {
    lead,
    id,
    product,
    status,
    investment_amount,
    created_at,
    assignedBy,
    reassignedTo,
  } = props;

  const params = useParams();
  const dispatch = useDispatch();

  const clearTextFields = () => {};

  const { currentUserDeals } = useSelector((state) => state.deal);
  const [update, setUpdate] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [showButtonControls, setShowButtonControls] = useState(
    Array(currentUserDeals.length).fill(false)
  );
  const [modalShow, setModalShow] = useState(false);
  const [currentDeal, setCurrentDeal] = useState({});

  const handleToggleControls = (index) => {
    const updatedControls = [...showButtonControls];
    updatedControls[index] = !updatedControls[index];
    setShowButtonControls(updatedControls);
  };

  const [filterType, setFilterType] = useState("all");
  let filterTypeRef = useRef(null);
  // const [dateFrom, setDateFrom] = useState(null);
  // const [dateTo, setDateTo] = useState(null);

  let dateTo = useRef(null);
  let dateFrom = useRef(null);

  const onViewDeal = (deal) => {
    setCurrentDeal(deal);
    setModalShow(true);
  };

  const onVerifyDelete = (id) => {
    swal({
      title: "Delete Deal",
      text: "Do you want to delete? ",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((result) => {
      if (result) {
        dispatch(deleteDeal(id));
      }
    });
  };

  const columns = [
    {
      name: "Customer",
      selector: (row) => row.customer,
      style: {
        fontWeight: "bold",
      },
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Email",
      selector: (row) => row.email && row.email,
      // sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.company,
    },
    {
      name: "Position",
      selector: (row) => row.position,
    },
    {
      name: "Status",
      cell: (row) => (
        <Badge bg={row.status === 'Open' ? "warning" : "success"}>
          {row.status === 'Open' ? "Open" : "Closed"}
        </Badge>
      ),
    },
    {
      name: "Created At",
      selector: (row) => moment.utc(row.created_at).format("ll"),
      sortable: true,
    },
    {
      name: "Updated At",
      selector: (row) => moment.utc(row.updated_at).format("ll"),
      sortable: true,
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <Dropdown as={ButtonGroup}>
    //       <Dropdown.Toggle
    //         as={Button}
    //         split
    //         variant='link'
    //         className='text-dark m-0 p-0'
    //       >
    //         <span className='icon icon-sm'>
    //           <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
    //         </span>
    //       </Dropdown.Toggle>

    //       <Dropdown.Menu>
    //         {authUser && authUser.role && authUser.role.name !== "PV" && (
    //           <Dropdown.Item as={Link} to={`/deal/${row.id}`}>
    //             <FontAwesomeIcon icon={faEye} className='me-2' /> View
    //           </Dropdown.Item>
    //         )}
    //         {authUser &&
    //           authUser.role &&
    //           (authUser.role.name !== "Sales Executive" ||
    //             authUser.role.name !== "Account Officer") && (
    //             <Dropdown.Item
    //               onClick={() => {
    //                 props.setModalShow(true);
    //                 props.setUpdate(true);
    //                 props.setDeal(props);
    //               }}
    //             >
    //               <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit
    //             </Dropdown.Item>
    //           )}
    //         {/* {authUser && authUser.role && authUser.role.name === "Admin" && (
    //           <Dropdown.Item
    //             onClick={() => {
    //               onVerifyDelete(id);
    //             }}
    //           >
    //             <FontAwesomeIcon icon={faTrash} className='me-2' /> Delete
    //           </Dropdown.Item>
    //         )} */}
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    //   style: {
    //     flexGrow: 1,
    //   },
    // },
  ];

  const filterByDateAndType = () => {
    setRefreshLoading(true);

    try {
      dispatch(
        getUserDealsFilter({
          filterType: filterTypeRef.current,
          dateFrom:
            dateFrom.current !== null
              ? dateFrom.current.toDate().toISOString().split("T")[0]
              : null,
          dateTo:
            dateTo.current !== null
              ? dateTo.current.toDate().toISOString().split("T")[0]
              : null,
          userId: params.id,
        })
      );

      setRefreshLoading(false);
    } catch (err) {
      console.error(err);
      setRefreshLoading(false);
    }
  };

  const clearFilter = () => {
    dispatch(
      getUserDealsFilter({
        filterType: "all",
        userId: params.id,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getUserDealsFilter({
        filterType: "all",
        userId: params.id,
      })
    );
  }, [dispatch, params.id]);

  return (
    <>
      <Link to='/users'>
        <Button variant='outline-danger' className='px-5 shadow-none my-4'>
          <div className='d-flex justify-content-center align-items-center gap-2'>
            <BsArrow90DegLeft />
            <p className='px-0 py-0 my-0 fw-bold'>Back</p>
          </div>
        </Button>
        {/* <h1 className='py-0'>Firstname Lastname</h1> */}
      </Link>
      <AdvancedFilter
        filterByDateAndType={filterByDateAndType}
        filterTypeRef={filterTypeRef}
        setFilterType={setFilterType}
        dateFrom={dateFrom}
        dateTo={dateTo}
        clearFilter={clearFilter}
      />
      <DataTables
        title={"User Deals"}
        data={currentUserDeals}
        columns={columns}
        progressPending={refreshLoading}
        className='mt-3 mb-5'
      />
      <DealModal
        // dispatch={dispatch}
        show={modalShow}
        update={update}
        deal={filteredDeal}
        onHide={() => setModalShow(false)}
        clearTextFields={clearTextFields}
      />
    </>
  );
};

export default UserDeals;
