import toast from "react-hot-toast";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { apiEndpoint } from "../Global";
import {
  ADD_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  GET_DEPARTMENTS,
  DEPARTMENTS_ERROR,
  LOADING_DEPARTMENT,
  FILTER_DEPARTMENTS,
  SET_CURRENT,
  CLEAR_CURRENT,
} from "./types";

// Get departments
export const getDepartments = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`${apiEndpoint}/departments`);

    dispatch({
      type: GET_DEPARTMENTS,
      payload: res.data,
    });
  } catch (err) {
    toast.error(err.response.data.errors);

    dispatch({
      type: DEPARTMENTS_ERROR,
      payload: err.response.data.errors,
    });
  }
};

// Add department
export const addDepartment = (formData, hideModal) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({
    type: LOADING_DEPARTMENT,
  });

  try {
    const res = await axios.post(
      `${apiEndpoint}/departments/create`,
      formData,
      config
    );

    toast.success(res.data.message);
    hideModal();

    dispatch({
      type: ADD_DEPARTMENT,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.errors);

    dispatch({
      type: DEPARTMENTS_ERROR,
      payload: err.response.data.errors,
    });
    hideModal();
  }
};

// Update department
export const updateDepartment = (department, hideModal) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({
    type: LOADING_DEPARTMENT,
  });

  try {
    const res = await axios.put(
      `${apiEndpoint}/departments/update/${department.id}`,
      department,
      config
    );

    toast.success(res.data.message);
    hideModal();

    dispatch({
      type: UPDATE_DEPARTMENT,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.error);
    hideModal();

    dispatch({
      type: DEPARTMENTS_ERROR,
      payload: err.response.data.error,
    });
  }
};

export const filterDepartments = (queryString) => async (dispatch) => {
  dispatch({
    type: FILTER_DEPARTMENTS,
    payload: queryString,
  });
};

// Delete department
export const deleteDepartment = (department) => async (dispatch) => {
  console.log(department.id);
  try {
    const res = await axios.delete(
      `${apiEndpoint}/departments/delete/${department.id}`
    );

    toast.success(res.data.message);

    dispatch({
      type: DELETE_DEPARTMENT,
      payload: department,
    });
  } catch (err) {
    toast.error(err.response.data.error);

    dispatch({
      type: DEPARTMENTS_ERROR,
      payload: err.response.data.error,
    });
  }
};

// Set current
export const setCurrent = (department) => (dispatch) => {
  dispatch({
    type: SET_CURRENT,
    payload: department,
  });
};

// Clear current
export const clearCurrent = () => (dispatch) => {
  dispatch({
    type: CLEAR_CURRENT,
  });
};

// Set Loading
export const setLoading = () => (dispatch) => {
  dispatch({
    type: LOADING_DEPARTMENT,
  });
};
