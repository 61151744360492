import {
  LOADING_LEAD,
  GET_LEADS,
  GET_USER_LEADS,
  ADD_LEAD,
  UPDATE_LEAD,
  DELETE_LEAD,
  FILTER_LEAD,
  LEAD_ERROR,
} from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { apiEndpoint } from "../Global";
import toast from "react-hot-toast";

// Load Leads
export const getLeads = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_LEAD });
    const res = await axios.get(`${apiEndpoint}/leads`);

    dispatch({
      type: GET_LEADS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: LEAD_ERROR,
      payload: err.response.data,
    });
  }
};

//Load User Leads
export const getUserLeads = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_LEAD });
    const res = await axios.get(`${apiEndpoint}/leads/me`);

    dispatch({
      type: GET_LEADS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: LEAD_ERROR,
      payload: err.response.data,
    });
  }
};

// Create Lead
export const createLead = (lead) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_LEAD });
    const res = await axios.post(`${apiEndpoint}/leads/create`, lead, config);

    dispatch({
      type: ADD_LEAD,
      payload: res.data.data,
    });
    toast.success(res.data.message);
    lead.props.onHide();
  } catch (err) {
    dispatch({
      type: LEAD_ERROR,
      payload: err.response.data,
    });
  }
};

// UPDATE LEAD
export const updateLead = (lead) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_LEAD });
    const res = await axios.put(
      `${apiEndpoint}/leads/update/${lead.id}`,
      lead,
      config
    );

    dispatch({
      type: UPDATE_LEAD,
      payload: res.data.data,
    });
    toast.success(res.data.message);
    lead.props.onHide();
  } catch (err) {
    dispatch({
      type: LEAD_ERROR,
      payload: err.response.data,
    });
  }
};

// FILTER LEAD
export const filteredLead = (search) => (dispatch) =>
  dispatch({ type: FILTER_LEAD, payload: search });

// DELETE LEAD
export const deleteLead = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_LEAD });
    const res = await axios.delete(`${apiEndpoint}/leads/delete/${id}`);

    dispatch({
      type: DELETE_LEAD,
      payload: id,
    });
    toast.success(res.data.message);
  } catch (err) {
    dispatch({
      type: LEAD_ERROR,
      payload: err.response.data,
    });
  }
};
