import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  faAddressBook,
  faFilter,
  faHandshake,
  faUsers,
  faWallet
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table
} from "@themesberg/react-bootstrap";

import { getActivities } from "../../actions/activityActions";
import { loadSalesUsers, loadUser } from "../../actions/authActions";
import {
  filteredDealByTypeClosed,
  filteredDealByTypeOpen,
  getDeals,
  getDealsCounts,
  getFilteredDeals
} from "../../actions/dealActions";
import { getLocationCounts, getLocations } from "../../actions/locationActions";
import { getProducts } from "../../actions/productActions";
import { CounterWidget, ProgressTrackWidget } from "../../components/Widgets";
import { formatAmount } from "../../Global";
import { DashboardModal } from "./DashboardModal";

const DashboardOverview = ({
  auth: { isAuthenticated, user },
  getLeads,
  getDeals,
  getActivities,
  getProducts,
  getLocations,
  getDealsCounts,
  getLocationCounts,
  getFilteredDeals,
  filteredDealByTypeOpen,
  filteredDealByTypeClosed,
  deal: {
    deals,
    total_pages,
    openPropertyAmount,
    closedPropertyAmount,
    openInvestmentAmount,
    closedInvestmentAmount,
    property_Inspection,
    investment_Inspection,
    totalOpenDeals,
    totalCloseDeals,
    noCloseDeals,
    today,
    week,
    month,
    total,
    loading,
  },
  product: { products },
  location: {
    mma_de,
    mma1_arrival,
    evergreen,
    legend,
    mm2,
    others,
    transcorp_abuja,
    Imma_de,
    Imma1_arrival,
    Ievergreen,
    Ilegend,
    Imm2,
    Iothers,
    Itranscorp_abuja,
  },
  activity: { activities, per_page, last_page },
}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [dashboardModalShow, setDashboardModalShow] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [from, setFrom] = React.useState("");
  const [to, setTo] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSalesUsers());
  }, [dispatch]);

  useEffect(() => {
    getDeals();
    getActivities();
    getProducts();
    getLocationCounts();
    getLocations();
    getDealsCounts();
  }, [
    getLeads,
    getDeals,
    getActivities,
    getProducts,
    getLocations,
    getDealsCounts,
    getLocationCounts,
    user,
  ]);

  if (user && user.role === null) {
    navigate("/settings");
  }
  if (user && user.role.name === "Secretary") {
    navigate("/records");
  }

  const type = "Property";
  // const loading = false;

  const onSubmit = (e) => {
    e.preventDefault();
    getFilteredDeals({ to, from, setModalShow });
  };

  const getDealOpen = () => {
    dispatch(
      filteredDealByTypeOpen({ num: 0, type: "Investment", status: "Open" })
    );
  };

  const getDealClosed = () => {
    dispatch(
      filteredDealByTypeClosed({ num: 0, type: "Investment", status: "Closed" })
    );
  };

  const dashboardStyles = {
    cursorPointer: {
      cursor: "pointer",
    },
  };

  return (
    <>
      <br />
      {user &&
        (user.role.name === "MD" ||
          user.role.name === "ED" ||
          user.role.name === "Admin") && (
          <div className='d-flex justify-content-between align-items-center'>
            <Button
              variant='primary'
              type='submit'
              onClick={() => {
                setModalShow(true);
              }}
            >
              <FontAwesomeIcon icon={faFilter} /> Filter
            </Button>

            <div className='d-flex'>
              <div className='me-3 text-center'>
                <div>Today</div>
                <span>{today}</span>
              </div>
              <div className='me-3 text-center'>
                <div>This Week</div>
                <span>{week}</span>
              </div>
              <div className='me-3 text-center'>
                <div>This Month</div>
                <span>{month}</span>
              </div>
            </div>
          </div>
        )}
      <br />
      <Row className='justify-content-md-center'>
        <Col xs={12} sm={6} xl={4} className='mb-4'>
          <Link to='/deals'>
            <CounterWidget
              category='Number of Leads'
              title={total ? total : total_pages}
              period='Feb 1 - Apr 1'
              percentage={deals && deals.length / 100}
              icon={faHandshake}
              iconColor='shape-tertiary'
            />
          </Link>
        </Col>
        <Col xs={12} sm={6} xl={4} className='mb-4'>
          <CounterWidget
            category='Total Open Leads'
            title={totalOpenDeals}
            period='Feb 1 - Apr 1'
            percentage={deals && deals.length / 100}
            icon={faUsers}
            iconColor='shape-secondary'
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className='mb-4'>
          <CounterWidget
            category='Total Closed Leads'
            title={totalCloseDeals}
            period='Feb 1 - Apr 1'
            percentage={deals && deals.length / 100}
            icon={faUsers}
            iconColor='shape-secondary'
          />
        </Col>
      </Row>

      <Row className='justify-content-md-center'>
        <Col
          xs={12}
          sm={6}
          xl={4}
          className='mb-4'
          style={dashboardStyles.cursorPointer}
          onClick={() => {
            setDashboardModalShow(true);
            setData(property_Inspection);
          }}
        >
          <CounterWidget
            category='Number of Property Inspections'
            title={property_Inspection.length}
            period='Feb 1 - Apr 1'
            percentage={deals && deals.length / 100}
            icon={faAddressBook}
            iconColor='shape-secondary'
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className='mb-4'>
          <CounterWidget
            category='Total Open Property Amount'
            title={formatAmount(openPropertyAmount)}
            period='Feb 1 - Apr 1'
            percentage={28.4}
            icon={faWallet}
            iconColor='shape-tertiary'
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className='mb-4'>
          <CounterWidget
            category='Total Closed Property Amount'
            title={formatAmount(closedPropertyAmount)}
            period='Feb 1 - Apr 1'
            percentage={28.4}
            icon={faWallet}
            iconColor='shape-tertiary'
          />
        </Col>
      </Row>

      {/* <Col xs={12} sm={6} xl={4} className="mb-4">
        <Carousel controls={false} touch={true}>
          <Carousel.Item></Carousel.Item>
          <Carousel.Item></Carousel.Item>
        </Carousel>
      </Col> */}
      <Row className='justify-content-md-center'>
        <Col
          xs={12}
          sm={6}
          xl={4}
          className='mb-4'
          style={dashboardStyles.cursorPointer}
          onClick={() => {
            setDashboardModalShow(true);
            setData(investment_Inspection);
          }}
        >
          <CounterWidget
            category='Number of Investment Inspections'
            title={investment_Inspection.length}
            period='Feb 1 - Apr 1'
            percentage={deals && deals.length / 100}
            icon={faUsers}
            iconColor='shape-secondary'
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          xl={4}
          className='mb-4'
          onClick={() => getDealOpen()}
        >
          <Link to='/deals'>
            <CounterWidget
              category='Total Open Investment Amount'
              title={formatAmount(openInvestmentAmount, type)}
              period='Feb 1 - Apr 1'
              percentage={28.4}
              icon={faWallet}
              iconColor='shape-tertiary'
            />
          </Link>
        </Col>
        <Col
          xs={12}
          sm={6}
          xl={4}
          className='mb-4'
          onClick={() => getDealClosed()}
        >
          <Link to='/deals'>
            <CounterWidget
              category='Total Closed Investment Amount'
              title={formatAmount(closedInvestmentAmount, type)}
              period='Feb 1 - Apr 1'
              percentage={28.4}
              icon={faWallet}
              iconColor='shape-tertiary'
            />
          </Link>
        </Col>
      </Row>
      {/* <Col xs={12} sm={6} xl={4} className="mb-4">
        <Carousel controls={false} touch={true}>
          <Carousel.Item></Carousel.Item>
          <Carousel.Item></Carousel.Item>
        </Carousel>
      </Col> */}
      {/* <Col xs={12} sm={6} xl={4} className="mb-4">
          <CircleChartWidget title="Deals Progress" data={trafficShares} />
        </Col> */}

      <Row>
        <Col xs={12} xl={12} className='mb-4'>
          <Row>
            <Col xs={12} xl={12} className='mb-4'>
              <Row>
                {/* <Col xs={12} lg={4} className="mb-4">
                  <TeamMembersWidget leads={leads} />
                </Col> */}
                {/* <Col xs={12} lg={4} className="mb-4">
                  <CircleChartWidget
                    title="Deals Status Chart"
                    data={trafficShares}
                    OpenDeals={OpenDeals}
                    CloseDeals={CloseDeals}
                  />
                </Col> */}
                {user &&
                  (user.role.name === "MD" || user.role.name === "ED") && (
                    <Col xs={12} lg={6} className='mb-4'>
                      <ProgressTrackWidget
                        mma_de={mma_de}
                        mma1_arrival={mma1_arrival}
                        evergreen={evergreen}
                        legend={legend}
                        mm2={mm2}
                        others={others}
                        transcorp_abuja={transcorp_abuja}
                        Imma_de={Imma_de}
                        Imma1_arrival={Imma1_arrival}
                        Ievergreen={Ievergreen}
                        Ilegend={Ilegend}
                        Imm2={Imm2}
                        Iothers={Iothers}
                        Itranscorp_abuja={Itranscorp_abuja}
                      />
                    </Col>
                  )}
                {/* {user &&
                  (user.role.name === "MD" ||
                    user.role.name === "ED" ||
                    user.role.name === "Sales Lead") && (
                    <Col xs={12} className="mb-4">
                      <PageVisitsTable
                        activities={activities}
                        per_page={per_page}
                        total_pages={total_pages}
                        last_page={last_page}
                      />
                    </Col>
                  )} */}
                <Col xs={12} lg={6} className='mb-4'>
                  <Card border='light' className='shadow-sm'>
                    <Card.Header className='border-bottom border-light'>
                      <h5 className='mb-0'>List of Closed Leads</h5>
                    </Card.Header>
                    <Card.Body>
                      {noCloseDeals && noCloseDeals.length > 0 ? (
                        <Table hover className='user-table align-items-center'>
                          <thead>
                            <tr>
                              <th className='border-bottom'>Lead Name</th>
                              <th className='border-bottom'>Phone</th>
                              <th className='border-bottom'>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {noCloseDeals.map((deal) => (
                              <tr key={deal.id}>
                                <td>{deal.customer}</td>
                                <td>{deal.phone}</td>
                                <td>
                                  {deal.currency === "NGN"
                                    ? formatAmount(
                                        deal.investment_amount,
                                        "NGN"
                                      )
                                    : formatAmount(deal.investment_amount)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <p className='text-center'>No Closed Leads yet</p>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className='mb-4'>
              <Form.Label>From</Form.Label>
              <Form.Control
                autoFocus
                type='date'
                name='from'
                value={from}
                onChange={(e) => setFrom(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='mb-4'>
              <Form.Label>To</Form.Label>

              <Form.Control
                autoFocus
                type='date'
                name='to'
                value={to}
                onChange={(e) => setTo(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className='text-white text-uppercase btn-block'
              variant='primary'
              type='submit'
            >
              {loading ? (
                <>
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                  />
                  <span className='text-capitalize'> Submitting</span>
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <DashboardModal
        show={dashboardModalShow}
        onHide={() => setDashboardModalShow(false)}
        data={data}
      />
    </>
  );
};

// DashboardOverview.propTypes = {
//   loadUser: PropTypes.func.isRequired,
//   isAuthenticated: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => ({
  auth: state.auth,
  deal: state.deal,
  activity: state.activity,
  product: state.product,
  location: state.location,
});

export default connect(mapStateToProps, {
  loadUser,
  getDeals,
  getDealsCounts,
  getActivities,
  getProducts,
  getLocations,
  getLocationCounts,
  getFilteredDeals,
  filteredDealByTypeOpen,
  filteredDealByTypeClosed,
})(DashboardOverview);
