import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DealModal from "./DealModal";
import DealTable from "./DealTable";

import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Row,
} from "@themesberg/react-bootstrap";

import { loadSalesUsers, loadUsers } from "../actions/authActions";
import { filterByStatus, getDeals } from "../actions/dealActions";
import { getLocations } from "../actions/locationActions";
import { getProducts } from "../actions/productActions";
import Transactions from "../pages/Transactions";
import { ExportToExcel } from "./ExportDeals";

const Deals = () => {
  const [modalShow, setModalShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [deal, setDeal] = useState(null);
  const [transactionModalShow, setTransactionModalShow] = useState(false);
  const [dealId, setDealId] = useState("");
  const [assignBy, setAssignBy] = useState("");
  const [status, setStatus] = useState("");

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { deals, current_page } = useSelector((state) => state.deal);

  useEffect(() => {
    dispatch(getDeals());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadUsers());
    // dispatch(loadSalesUsers());
    dispatch(getProducts());
    dispatch(getLocations());
    // setModalShow(true);
  }, [dispatch]);

  const clearTextFields = () => {};

  return (
    <>
      {!transactionModalShow ? (
        <>
          <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
            <div className='d-block mb-4 mb-md-0'>
              <Breadcrumb
                className='d-none d-md-inline-block'
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item>
                  <FontAwesomeIcon icon={faHome} /> Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Leads</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Leads</h4>
              <p className='mb-0'>List of all executed leads.</p>
            </div>
            {/* <div className="btn-toolbar mb-2 mb-md-0"> */}
            <ButtonGroup>
              {/* <Button variant="outline-primary" size="sm">
                  Share
                </Button> */}
            </ButtonGroup>
            {/* </div> */}
            {/* <ExportToExcel apiData={deals} fileName={"data"} /> */}
            <ExportToExcel
              apiData={deals}
              fileName={`lead_${current_page}`}
            />
          </div>

          <div className='table-settings mb-4'>
            <Row className='justify-content-between align-items-center'>
              <Col xs={6} md={6} lg={3} xl={4}>
                <div className='d-flex'>
                  <InputGroup className='me-2'>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                      type='text'
                      placeholder='Search by Customer name'
                      name='status'
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    />
                  </InputGroup>
                  <Button
                    variant='primary'
                    type='button'
                    onClick={() =>
                      status && dispatch(filterByStatus({ customer: status }))
                    }
                  >
                    Search
                  </Button>
                </div>
              </Col>
              {user &&
                user.role &&
                (user.role.name === "MD" ||
                  user.role.name === "PV" ||
                  user.role.name === "Sales Executive" ||
                  user.role.name === "Sales Lead" ||
                  user.role.name === "Supervisor") && (
                  <Col xs={5} md={2} xl={2}>
                    <Button
                      variant='primary'
                      type='button'
                      className='w-100 text-uppercase'
                      onClick={() => {
                        setModalShow(true);
                        setUpdate(false);
                        clearTextFields();
                      }}
                    >
                      Add Lead
                    </Button>
                  </Col>
                )}
            </Row>
          </div>

          <DealTable
            setModalShow={setModalShow}
            setUpdate={setUpdate}
            setDeal={setDeal}
            dispatch={dispatch}
            onShow={() => setTransactionModalShow(true)}
            setDealId={setDealId}
            setAssignBy={setAssignBy}
          />
          <DealModal
            // dispatch={dispatch}
            show={modalShow}
            update={update}
            deal={deal}
            onHide={() => setModalShow(false)}
            clearTextFields={clearTextFields}
          />
        </>
      ) : (
        <Transactions
          show={transactionModalShow}
          onHide={() => setTransactionModalShow(false)}
          deal={dealId}
          findUser={assignBy}
        />
      )}
    </>
  );
};

export default Deals;
