import {
  LOADING_TRANSACTION,
  GET_TRANSACTIONS,
  ADD_TRANSACTION,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION,
  FILTER_TRANSACTION,
  CLEAR_TRANSACTION,
  TRANSACTION_ERROR,
} from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { apiEndpoint } from "../Global";
import toast from "react-hot-toast";

// Load Transaction
export const getTransaction = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_TRANSACTION });
    const res = await axios.get(`${apiEndpoint}/transactions/lead/${id}`);

    dispatch({
      type: GET_TRANSACTIONS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: TRANSACTION_ERROR,
      payload: err.response.data,
    });
  }
};

// Create TRANSACTION
export const createTransaction = (transaction) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_TRANSACTION });
    const res = await axios.post(
      `${apiEndpoint}/transactions/create`,
      transaction,
      config
    );

    dispatch({
      type: ADD_TRANSACTION,
      // payload: res.data.data,
    });
    toast.success(res.data.message);
  } catch (err) {
    dispatch({
      type: TRANSACTION_ERROR,
      payload: err.response.data,
    });
  }
};

// UPDATE TRANSACTION
export const updateTransaction = (transaction) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_TRANSACTION });
    const res = await axios.put(
      `${apiEndpoint}/transactions/update/${transaction.id}`,
      transaction,
      config
    );

    dispatch({
      type: ADD_TRANSACTION,
      // payload: res.data.data,
    });
    toast.success(res.data.message);
  } catch (err) {
    dispatch({
      type: TRANSACTION_ERROR,
      payload: err.response.data,
    });
  }
};

// CLEAR TRANSACTION
export const clearTransaction = () => (dispatch) =>
  dispatch({ type: CLEAR_TRANSACTION });

// FILTER TRANSACTION
export const filteredTransaction = (search) => (dispatch) =>
  dispatch({ type: FILTER_TRANSACTION, payload: search });

// DELETE TRANSACTION
export const deleteTransaction = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_TRANSACTION });
    const res = await axios.delete(`${apiEndpoint}/transactions/delete/${id}`);

    dispatch({
      type: DELETE_TRANSACTION,
      payload: id,
    });
    toast.success(res.data.message);
  } catch (err) {
    dispatch({
      type: TRANSACTION_ERROR,
      payload: err.response.data,
    });
  }
};
