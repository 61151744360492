import React from "react";
import { Image } from "@themesberg/react-bootstrap";
import { Circle2, Lines } from "react-preloaders";
import SujimotoLogo from '../assets/img/preloader.png';

import ReactLogo from "../assets/img/technologies/react-logo-transparent.svg";

const Preloader = (props) => {
  const { show } = props;

  return (
    <div
      className={`preloader bg-soft flex-column justify-content-center align-items-center ${
        show ? "" : "show"
      }`}
    >
      <Image className="loader-element animate__animated animate__jackInTheBox" src={SujimotoLogo} height={90} />
    
    </div>
  );
};

export default Preloader;
