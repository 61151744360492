import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import {
  faCheck,
  faCog,
  faHome,
  faInfoCircle,
  faSearch,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import emptyProduct from "../../../assets/syntax-themes/93134-not-found";
import EmptyPreloader from "../../../components/EmptyPreloader";
import Preloader from "../../../components/Preloader";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Modal,
  Card,
  Pagination,
  Nav,
  Table,
  TableRow,
} from "@themesberg/react-bootstrap";

import { TransactionsTable } from "../../../components/Tables";
import TeamsModal from "./TeamsModal";
import Lottie from "react-lottie";

import { connect, useDispatch, useSelector } from "react-redux";
import {
  getDepartments,
  filterDepartments,
} from "../../../actions/departmentActions";
import DepartmentItem from "../Departments/DepartmentItem";
// import ExportToExcel from "../../../components/";

const Teams = ({ department: { departments, filtered } }) => {
  const tableRef = useRef();
  const [modalShow, setModalShow] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [departmentPerPage] = React.useState(10);

  const paginate = ({ selected }) => setCurrentPage(selected);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyProduct,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const indexOfLastDepartment = currentPage * departmentPerPage;
  const filteredDepartments = filtered
    ? filtered &&
      filtered.slice(
        indexOfLastDepartment,
        indexOfLastDepartment + departmentPerPage
      )
    : departments &&
      departments.slice(
        indexOfLastDepartment,
        indexOfLastDepartment + departmentPerPage
      );

  const pageCount = Math.ceil(
    filtered
      ? filtered && filtered.length / departmentPerPage
      : departments && departments.length / departmentPerPage
  );

  useEffect(() => {
    dispatch(getDepartments());
  }, [dispatch]);

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Teams</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Teams</h4>
          <p className='mb-0'>This is the list of all teams.</p>
        </div>
        {departments.length > 0 && (
          <div className='btn-toolbar mb-2 mb-md-0'>
            <ButtonGroup>
              {/* <ExportToExcel fileName='teams' /> */}
            </ButtonGroup>
          </div>
        )}
      </div>

      <div className='table-settings mb-4'>
        <Row className='justify-content-between align-items-center'>
          <Col xs={6} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Search'
                onChange={(e) => {
                  console.log(e.target.value);
                  dispatch(filterDepartments(e.target.value));
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={5} md={2} xl={3}>
            <Row>
              <Col>
                <Button
                  variant='primary'
                  type='button'
                  className='w-100 text-uppercase'
                  onClick={() => setModalShow(true)}
                >
                  Add Team
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* <TransactionsTable /> */}
      {departments.length > 0 ? (
        <>
          <Card
            border='light'
            className='table-wrapper table-responsive shadow-sm'
          >
            <Card.Body className='pt-0'>
              <Table
                hover
                className='user-table align-items-center'
                id='table-to-xls'
              >
                <thead>
                  <tr>
                    <th className='border-bottom'>Name</th>
                    <th className='border-bottom'>Created At</th>
                    <th className='border-bottom'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDepartments.length > 0 ? (
                    filteredDepartments.map((department) => (
                      <DepartmentItem
                        key={department.id}
                        department={department}
                      />
                    ))
                  ) : (
                    <Preloader />
                  )}
                </tbody>
              </Table>
              <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
                <div className='paginate_container mx-auto'>
                  <ReactPaginate
                    previousLabel='Prev'
                    nextLabel='Next'
                    pageCount={pageCount}
                    onPageChange={paginate}
                    containerClassName={"paginationBtns"}
                    previousLinkClassName={"previousBtn"}
                    nextLinkClassName={"nextBtn"}
                    disabledClassName={"disabledBtn"}
                    activeClassName={"activeBtn"}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={0}
                  />
                </div>
              </Card.Footer>
            </Card.Body>
          </Card>
          <TeamsModal show={modalShow} onHide={() => setModalShow(false)} />
        </>
      ) : (
        <Lottie options={defaultOptions} height={300} width={300} />
      )}
    </>
  );
};

Teams.propTypes = {
  loading: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  departments: PropTypes.object.isRequired,
  getDepartments: PropTypes.func.isRequired,
  filterDepartments: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  department: state.department,
});

export default connect(mapStateToProps, { getDepartments, filterDepartments })(
  Teams
);
