import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { connect, useSelector, useDispatch } from "react-redux";
import {
  addDepartment,
  updateDepartment,
} from "../../../actions/departmentActions";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDepartments } from "../../../actions/departmentActions";

import Alert from "../../../components/Alert";
import {
  Button,
  Form,
  InputGroup,
  FormGroup,
  Modal,
  Spinner,
} from "@themesberg/react-bootstrap";

const TeamsModal = ({
  department: { loading, departments },
  addDepartment,
  show,
  onHide,
}) => {
  const [team, setTeam] = React.useState({
    name: "",
    departmentId: "",
    supervisorId: "",
  });

  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const { name, departmentId, supervisorId } = team;

  const dispatch = useDispatch();

  const supervisorRef = useRef();

  const { current } = useSelector((state) => state.department);

  useEffect(() => {
    dispatch(getDepartments);
  }, [dispatch]);

  useEffect(() => {
    if (current !== null) {
      setTeam({ name: current.name });
    }
  }, [current]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (current !== null) {
      team.id = current.id;
      dispatch(updateDepartment(team, onHide));
    } else {
      // addDepartment(department, onHide);
    }

    // clear form
    setTeam({
      name: "",
    });
  };

  const onChange = (e) => setTeam({ ...team, [e.target.name]: e.target.value });

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {current ? "Update Team" : "Add Team"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='name'
                defaultValue={name}
                placeholder='Enter Team Name'
                onChange={onChange}
              />
            </InputGroup>
          </Form.Group>
          <FormGroup>
            <InputGroup>
              <Form.Select
                name='departmentId'
                value={departmentId}
                onChange={(e) => {
                  setTeam({ departmentId: e.target.value });
                  setSelectedDepartment(
                    departments.filter(
                      (department) =>
                        department.id === e.target.value
                    )
                  );
                  supervisorRef.current = departments.filter(
                    (department) =>
                      department.id === e.target.value
                  );
                  console.log(supervisorRef.current[0].users.length);
                }}
              >
                <option value=''>Choose a Department</option>
                {departments.map((department) => (
                  <option value={department.id} key={department.id}>
                    {department.name}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </FormGroup>
          <FormGroup className='mt-4'>
            <InputGroup>
              <Form.Select
                name='supervisorId'
                value={supervisorId}
                onChange={onChange}
              >
                <option value=''>Select Supervisor</option>
                console.log(supervisorRef.current[0].users.length)
                {/* {supervisorRef.current[0].users.length > 0 && supervisorRef.current[0].users.map((supervisor) => (
                  <option value={supervisor.id} key={supervisor.id}>
                    {supervisor.firstname}
                  </option>
                ))} */}
              </Form.Select>
            </InputGroup>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='text-white text-uppercase btn-block'
            variant='primary'
            disabled={
              loading ||
              name === "" ||
              departmentId === "" ||
              supervisorId === ""
                ? true
                : false
            }
            type='submit'
          >
            {loading ? (
              <>
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                ></Spinner>
                <span className='text-capitalize'> Submitting</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

TeamsModal.propTypes = {
  loading: PropTypes.object.isRequired,
  departments: PropTypes.object.isRequired,
  addDepartment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  department: state.department,
});
export default connect(mapStateToProps, { addDepartment })(TeamsModal);
