import {
  LOADING_LEAD,
  GET_LEADS,
  GET_USER_LEADS,
  ADD_LEAD,
  UPDATE_LEAD,
  FILTER_LEAD,
  DELETE_LEAD,
  LEAD_ERROR,
} from "../actions/types";

const INITIALSTATE = {
  leads: [],
  userleads: [],
  next_page: "",
  previous_page: "",
  per_page: 0,
  total_pages: 0,
  filtered: null,
  loading: false,
  error: null,
};

const leadReducer = (state = INITIALSTATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_LEAD:
      return {
        ...state,
        loading: true,
      };
    case GET_LEADS:
      return {
        ...state,
        leads: payload.data,
        next_page: payload.next_page_url,
        previous_page: payload.previous_page_url,
        per_page: payload.per_page,
        total_pages: payload.total,
        loading: false,
      };
    case GET_USER_LEADS:
      return {
        ...state,
        userleads: payload.data,
        next_page: payload.next_page_url,
        previous_page: payload.previous_page_url,
        per_page: payload.per_page,
        total_pages: payload.total,
        loading: false,
      };
    case ADD_LEAD:
      return {
        ...state,
        leads: [payload, ...state.leads],
        loading: false,
      };
    case UPDATE_LEAD:
      return {
        ...state,
        leads: state.leads.map((lead) =>
          lead.id === payload.id ? payload : lead
        ),
        loading: false,
      };
    case FILTER_LEAD:
      return {
        ...state,
        filtered: state.leads.filter((lead) => {
          const regex = new RegExp(`${payload}`, "gi");
          return (
            lead.customer.match(regex) ||
            lead.phone.match(regex) ||
            lead.company.match(regex)
          );
        }),
        loading: false,
      };
    case DELETE_LEAD:
      return {
        ...state,
        leads: state.leads.filter((lead) => lead.id !== payload),
        loading: false,
      };
    case LEAD_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default leadReducer;
