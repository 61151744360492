// const apiEndpoint = "https://databaseapi.sujimotonig.com/api";

// Digital Ocean API Endpoint
// const apiEndpoint = "https://urchin-app-5vr2f.ondigitalocean.app/api";
// const apiEndpoint = 'https://orca-app-mm3zv.ondigitalocean˜.app/api'
const apiEndpoint = 'https://seashell-app-39ic2.ondigitalocean.app/api';

// const apiEndpoint = "http://127.0.0.1:8000/api";

const formatAmount = (amount, type) => {
  if (type) {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(+amount);
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(+amount);
};

export { apiEndpoint, formatAmount };