export const Routes = {
  // pages
  DashboardOverview: { path: "/" },
  Transactions: { path: "/transactions" },
  Settings: { path: "/settings" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/billing" },
  Setup: { path: "/setup" },
  Invoice: { path: "/invoice" },
  Users: { path: "/users" },
  UserDeals: { path: "/users/:id" },
  Leads: { path: "/leads" },
  Records: { path: "/records" },
  Teams: { path: "/teams" },
  Deals: { path: "/deals" },
  Signin: { path: "/auth/sign-in" },
  Signup: { path: "/auth/sign-up" },
  ForgotPassword: { path: "/auth/forgot-password" },
  ResetPassword: { path: "/auth/reset-password" },
  Lock: { path: "/auth/lock" },
  NotFound: { path: "/404" },
  ServerError: { path: "/500" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
