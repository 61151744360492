import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "react-lottie";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";

import {
  faCheck,
  faCog,
  faEllipsisH,
  faEdit,
  faTrashAlt,
  faEye,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Modal,
  Card,
  Table,
  Spinner,
  Nav,
  Pagination,
} from "@themesberg/react-bootstrap";
import { formatAmount } from "../Global";
import emptyProduct from "../assets/syntax-themes/93134-not-found";

import {
  getRecordTypes,
  filteredTypeRecords,
  updateRecordType,
  createRecordType,
  deleteRecordType,
} from "../actions/recordActions";
import moment from "moment-timezone";

const RecordType = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [recordType, setRecordType] = React.useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getRecordTypes());
  }, [dispatch]);

  return (
    <>
      <div className='table-settings mb-4'>
        <Row className='justify-content-between align-items-center'>
          <Col xs={6} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Search'
                onChange={(e) => dispatch(filteredTypeRecords(e.target.value))}
              />
            </InputGroup>
          </Col>

          <Col xs={5} md={2} xl={3}>
            <Button
              variant='primary'
              type='button'
              className='w-100 text-uppercase'
              onClick={() => {
                setModalShow(true);
                setUpdate(false);
              }}
            >
              Add Record Type
            </Button>
          </Col>
        </Row>
      </div>

      <RecordTypeTable
        setModalShow={setModalShow}
        setUpdate={setUpdate}
        setRecordType={setRecordType}
        dispatch={dispatch}
      />
      <RecordTypeModal
        dispatch={dispatch}
        show={modalShow}
        update={update}
        recordType={recordType}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default RecordType;

const RecordTypeModal = (props) => {
  const [recordType, setRecordType] = React.useState({
    name: "",
    location: "",
  });
  const { loading } = useSelector((state) => state.record);

  const { name, location } = recordType;

  const onSubmit = (e) => {
    e.preventDefault();
    props.update
      ? props.dispatch(
          updateRecordType({ id: props.recordType.id, name, location, props })
        )
      : props.dispatch(createRecordType({ name, location, props }));
  };

  React.useEffect(() => {
    setRecordType({
      name: props.recordType && props.recordType.name,
      location: props.recordType && props.recordType.location,
    });
  }, [props]);

  const onChange = (e) =>
    setRecordType({ ...recordType, [e.target.name]: e.target.value });

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {`${props.update ? "Update" : "Add"} Record Type`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='name'
                value={name}
                placeholder='Enter name'
                onChange={onChange}
              ></Form.Control>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='location'
                value={location}
                placeholder='Enter Location'
                onChange={onChange}
              />
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='text-white text-uppercase btn-block'
            disabled={name ? false : true}
            variant='primary'
            type='submit'
          >
            {loading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' />
                <span className='text-capitalize'> Submitting</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const RecordTypeTable = (props) => {
  const { recordTypes, filtered } = useSelector((state) => state.record);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [RecordTypePerPage] = React.useState(10);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyProduct,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const paginate = ({ selected }) => setCurrentPage(selected);

  const indexOfLastRecordType = currentPage * RecordTypePerPage;
  const currentRecordType = filtered
    ? filtered &&
      filtered.slice(
        indexOfLastRecordType,
        indexOfLastRecordType + RecordTypePerPage
      )
    : recordTypes &&
      recordTypes.slice(
        indexOfLastRecordType,
        indexOfLastRecordType + RecordTypePerPage
      );

  const pageCount = Math.ceil(
    filtered
      ? filtered && filtered.length / RecordTypePerPage
      : recordTypes && recordTypes.length / RecordTypePerPage
  );

  const TableRow = (props) => {
    const { name, location, created_at } = props;

    const onVerifyDelete = (id) => {
      swal({
        title: "Delete Record Type",
        text: "Do you want to delete? ",
        icon: "warning",
        buttons: ["Cancel", true],
        dangerMode: true,
      }).then((result) => {
        if (result) {
          props.dispatch(deleteRecordType(id));
        }
      });
    };

    return (
      <tr>
        <td>
          <span className='fw-normal'>{name}</span>
        </td>
        <td>
          <span className='fw-normal'>{location}</span>
        </td>

        <td>
          <span className='fw-normal'>
            {moment.utc(created_at).format("ll")}
          </span>
        </td>

        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant='link'
              className='text-dark m-0 p-0'
            >
              <span className='icon icon-sm'>
                <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  props.setModalShow(true);
                  props.setUpdate(true);
                  props.setRecordType(props);
                }}
              >
                <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className='text-danger'
                onClick={() => onVerifyDelete(props.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {currentRecordType.length > 0 ? (
        <>
          <Card
            border='light'
            className='table-wrapper table-responsive shadow-sm'
          >
            <Card.Body className='pt-0'>
              <Table hover className='user-table align-items-center'>
                <thead>
                  <tr>
                    <th className='border-bottom'>Name</th>
                    <th className='border-bottom'>Location</th>
                    <th className='border-bottom'>Created Date</th>
                    <th className='border-bottom'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRecordType.map((record) => (
                    <TableRow
                      key={record.id}
                      {...record}
                      setModalShow={props.setModalShow}
                      setUpdate={props.setUpdate}
                      setRecordType={props.setRecordType}
                      dispatch={props.dispatch}
                    />
                  ))}
                </tbody>
              </Table>
              <div className='my-3' />
              {/* <Card.Footer className="border-0 d-lg-flex align-items-center justify-content-between"></Card.Footer> */}
            </Card.Body>
            <div className='paginate_container mx-auto'>
              <ReactPaginate
                previousLabel='Prev'
                nextLabel='Next'
                pageCount={pageCount}
                onPageChange={paginate}
                containerClassName={"paginationBtns"}
                previousLinkClassName={"previousBtn"}
                nextLinkClassName={"nextBtn"}
                disabledClassName={"disabledBtn"}
                activeClassName={"activeBtn"}
              />
            </div>
          </Card>
        </>
      ) : (
        <Lottie options={defaultOptions} height={300} width={300} />
      )}
    </>
  );
};
