import moment from "moment-timezone";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ServerDataTable from "./DataTable/UsersDataTable";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "react-lottie";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

import {
  faEdit,
  faEllipsisH,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Table,
} from "@themesberg/react-bootstrap";
import emptyProduct from "../assets/syntax-themes/93134-not-found";
import { formatAmount } from "../Global";

import {
  deleteDeal,
  filteredDealByTypeOpen,
  getDeals,
} from "../actions/dealActions";

export const DealTable = (props) => {
  // const [currentPage, setCurrentPage] = React.useState(0);
  // const [dealPerPage] = React.useState(10);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyProduct,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const dispatch = useDispatch();

  const { filtered, deals, loading, last_page, current_page, status } =
    useSelector((state) => state.deal);

  const { users, salesUsers, user } = useSelector((state) => state.auth);

  let authUser = user;

  const paginate = ({ selected }) => {
    if (status === "Open") {
      dispatch(
        filteredDealByTypeOpen({
          num: selected,
          type: "Investment",
          status: "Open",
        })
      );
    } else if (status === "Closed") {
      dispatch(
        filteredDealByTypeOpen({
          num: selected,
          type: "Investment",
          status: "Closed",
        })
      );
    } else {
      dispatch(getDeals(selected));
    }
  };

  const TableRow = (props) => {
    const {
      lead,
      id,
      product,
      status,
      investment_amount,
      created_at,
      user,
      assignedBy,
      reassignedTo,
    } = props;

    const onVerifyDelete = (id) => {
      swal({
        title: "Delete Deal",
        text: "Do you want to delete? ",
        icon: "warning",
        buttons: ["Cancel", true],
        dangerMode: true,
      }).then((result) => {
        if (result) {
          dispatch(deleteDeal(id));
        }
      });
    };

    // const findUser = users.find((user) => user.id === props.assignedById);

    return (
      <tr>
        <td>
          <span className='fw-normal'>{`${
            user && user.firstname
              ? `${user && user.firstname} ${user && user.lastname}`
              : "Not Yet Assigned"
          }`}</span>
        </td>

        <td>
          <span className='fw-normal'>{`${assignedBy && assignedBy.firstname} ${
            assignedBy && assignedBy.lastname
          }`}</span>
        </td>
        <td>
          <span className='fw-normal'>{`${
            reassignedTo
              ? `${reassignedTo && reassignedTo.firstname} ${
                  reassignedTo && reassignedTo.lastname
                }`
              : "Not Yet Assigned"
          }`}</span>
        </td>
        <td>
          <span className='fw-normal'>{props && props.customer}</span>
        </td>
        <td>
          <span className='fw-normal'>{props && props.phone}</span>
        </td>
        <td>
          <span className='fw-normal'>{props && props.company}</span>
        </td>
        <td>
          <span className='fw-normal'>{props && props.position}</span>
        </td>
        <td>
          <span className='fw-normal'>
            {props && props.location && props.location.name}
          </span>
        </td>
        <td>
          <span className='fw-normal'>{product && product.name}</span>
        </td>
        {product && product.type === "Investment" ? (
          <td>
            <span className='fw-normal'>
              {formatAmount(investment_amount, product.type)}
            </span>
          </td>
        ) : (
          <td>
            <span className='fw-normal'>
              {formatAmount(product && product.amount)}
            </span>
          </td>
        )}
        <td>
          {status === "Open" ? (
            <Badge bg='secondary'>{status}</Badge>
          ) : (
            <Badge bg='success'>{status}</Badge>
          )}
        </td>
        <td>
          <span className='fw-normal'>
            {moment.utc(created_at).format("ll")}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant='link'
              className='text-dark m-0 p-0'
            >
              <span className='icon icon-sm'>
                <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {authUser && authUser.role && authUser.role.name !== "PV" && (
                <Dropdown.Item
                  onClick={() => {
                    props.showModal();
                    props.setDealId(props);
                    props.setAssignBy(assignedBy);
                  }}
                >
                  <FontAwesomeIcon icon={faEye} className='me-2' /> View
                </Dropdown.Item>
              )}
              {authUser &&
                authUser.role &&
                (authUser.role.name !== "Sales Executive" ||
                  authUser.role.name !== "Account Officer") && (
                  <Dropdown.Item
                    onClick={() => {
                      props.setModalShow(true);
                      props.setUpdate(true);
                      props.setDeal(props);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit
                  </Dropdown.Item>
                )}
              {authUser && authUser.role && authUser.role.name === "Admin" && (
                <Dropdown.Item
                  onClick={() => {
                    onVerifyDelete(id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} className='me-2' /> Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {deals && deals.length > 0 ? (
        <>
          {!loading ? (
            <Card
              border='light'
              className='table-wrapper table-responsive shadow-sm'
            >
              <Card.Body className='pt-0'>
                <Table
                  id='table-to-xls'
                  hover
                  className='user-table align-items-center'
                >
                  <thead>
                    <tr>
                      <th className='border-bottom'>Sales Lead</th>
                      <th className='border-bottom'>Registered By</th>
                      <th className='border-bottom'>Assigned To</th>
                      <th className='border-bottom'>Lead Name</th>
                      <th className='border-bottom'>Lead Phone</th>
                      <th className='border-bottom'>Lead Company</th>
                      <th className='border-bottom'>Lead Position</th>
                      <th className='border-bottom'>Location</th>
                      <th className='border-bottom'>Product</th>
                      <th className='border-bottom'>Amount</th>
                      <th className='border-bottom'>Status</th>
                      <th className='border-bottom'>Created Date</th>
                      <th className='border-bottom'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtered !== null
                      ? filtered.map((deal) => (
                          <TableRow
                            key={deal.id}
                            {...deal}
                            setModalShow={props.setModalShow}
                            setUpdate={props.setUpdate}
                            setDeal={props.setDeal}
                            showModal={props.onShow}
                            setDealId={props.setDealId}
                            setAssignBy={props.setAssignBy}
                          />
                        ))
                      : deals.map((deal) => (
                          <TableRow
                            key={deal.id}
                            {...deal} 
                            setModalShow={props.setModalShow}
                            setUpdate={props.setUpdate}
                            setDeal={props.setDeal}
                            showModal={props.onShow}
                            setDealId={props.setDealId}
                            setAssignBy={props.setAssignBy}
                          />
                        ))}
                  </tbody>
                </Table>

                <div className='my-3' />
              </Card.Body>
              <div className='paginate_container mx-auto'>
                <ReactPaginate
                  previousLabel='Prev'
                  nextLabel='Next'
                  pageCount={last_page}
                  onPageChange={paginate}
                  containerClassName={"paginationBtns"}
                  previousLinkClassName={"previousBtn"}
                  nextLinkClassName={"nextBtn"}
                  disabledClassName={"disabledBtn"}
                  activeClassName={"activeBtn"}
                />
              </div>
            </Card>
          ) : (
            <Lottie options={defaultOptions} height={300} width={300} />
          )}
        </>
      ) : (
        <Lottie options={defaultOptions} height={300} width={300} />
      )}
    </>
  );
};

export default DealTable;
