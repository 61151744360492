import React, { useCallback, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import CustomLoader from "./CustomLoader";
import FilterComponent from "./FilterComponent";

const customStyles = {
  header: {
    style: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
  },
  rows: {
    style: {
      minHeight: "50px", // override the row height
      fontSize: ".85rem",
    },
  },
  headCells: {
    style: {
      // paddingLeft: "8px", // override the cell padding for head cells
      // paddingRight: "8px",
      fontWeight: "bold",
      fontSize: ".85rem",
      textTransform: "uppercase",
    },
  },
  cells: {
    style: {
      // paddingLeft: "8px", // override the cell padding for data cells
      // paddingRight: "8px",
      fontSize: ".85rem",
    },
  },
};

const DataTables = (props) => {
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [data, setData] = useState(props.data);
  const [perPage, setPerPage] = useState(10);
  const [tickets, setTickets] = useState([]);

  const filteredItems = tickets.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const handlePageChange = (page) => {
    console.log("page changed");
  };

  const handlePageRowsChange = (numRows, currentPage) => {
    console.log(numRows);
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => {
          // setFilterText(e.target.value);
        }}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const convertArrayOfObjectsToCSV = useCallback(
    (array) => {
      let result;

      const columnDelimiter = ",";
      const lineDelimiter = "\n";
      const keys = Object.keys(props.data[0]);

      result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      array.forEach((item) => {
        let ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;

          result += item[key];
          // eslint-disable-next-line no-plusplus
          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    },
    [props.data]
  );

  const downloadCSV = useCallback(
    (array) => {
      const link = document.createElement("a");
      let csv = convertArrayOfObjectsToCSV(props.data);
      if (csv == null) return;

      const filename = `${props.title}.csv`;

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute("href", encodeURI(csv));
      link.setAttribute("download", filename);
      link.click();
    },
    [convertArrayOfObjectsToCSV]
  );

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(props.data)} />,
    [props.data, downloadCSV]
  );

  const handleRowClicked = (row) => {
    const updatedData = data.map((item) => {
      if (row.id !== item.id) {
        return item;
      }

      return {
        ...item,
      };
    });
    console.log(row);

    setData(updatedData);
  };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
    noRowsPerPage: true,
  };

  return (
    <div className='py-5 px-0 px-lg-4'>
      {loading ? (
        "Fetching data, please wait..."
      ) : (
        <DataTable
          title={props.title}
          columns={props.columns}
          data={props.data}
          fixedHeader
          fixedHeaderScrollHeight='700px'
          actions={actionsMemo}
          progressPending={props.refreshLoading}
          progressComponent={<CustomLoader />}
          paginationResetDefaultPage={props.resetPaginationToggle}
          paginationComponentOptions={paginationComponentOptions}
          pagination
          // paginationServer
          // paginationTotalRows={props.totalRows}
          // onChangeRowsPerPage={props.handlePageRowsChange}
          // onChangePage={props.handlePageChange}
          responsive
          customStyles={customStyles}
          subHeader
          // subHeaderComponent={subHeaderComponent}
          // onRowClicked={handleRowClicked}
        />
      )}
    </div>
  );
};

export default DataTables;
