import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormCheck,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/sujimoto-logo.png";
import Alert from "../../components/Alert";
import { Routes } from "../../routes";

import { connect } from "react-redux";
import { login } from "../../actions/authActions";

const Signin = ({ auth: { loading, isAuthenticated }, login }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { email, password } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      Alert("Email is required to sign in", "error");
    } else if (!pattern.test(email)) {
      Alert("Please include a valid email address", "error");
    } else if (password === "") {
      Alert("Password is required", "error");
    } else {
      login({ email, password });
    }
  };

  return (
    <main>
      <section className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
        <Container>
          <p className='text-center'>
            <Image
              src={Logo}
              className='bg-fluid my-3'
              width={250}
              height={60}
              style={{ objectFit: "contain" }}
            />
          </p>

          <Row className='justify-content-center'>
            <Col
              xs={12}
              className='d-flex align-items-center justify-content-center'
            >
              <div className='bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                <div className='text-center text-md-center mb-4 mt-md-0'>
                  <h3 className='mb-0'>Sign in to your dashboard</h3>
                </div>
                <Form className='mt-5 mb-5' onSubmit={onSubmit}>
                  <Form.Group id='email' className='mb-4'>
                    {/* <Form.Label>Email Address</Form.Label> */}
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type='email'
                        name='email'
                        value={email}
                        placeholder='Email'
                        onChange={onChange}
                        className='email'
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id='password' className='mb-4'>
                      {/* <Form.Label>Your Password</Form.Label> */}
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          className='password'
                          type='password'
                          name='password'
                          value={password}
                          placeholder='Password'
                          onChange={onChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                      <Form.Check type='checkbox'>
                        <FormCheck.Input id='defaultCheck5' className='me-2' />
                        <FormCheck.Label
                          htmlFor='defaultCheck5'
                          className='mb-0'
                        >
                          Remember me
                        </FormCheck.Label>
                      </Form.Check>
                      <Card.Link
                        className='small text-end'
                        href='/auth/reset-password'
                      >
                        Lost password?
                      </Card.Link>
                    </div>
                  </Form.Group>
                  <Button
                    variant='primary'
                    disabled={loading ? true : false}
                    type='submit'
                    className='w-100 text-uppercase sign_in_btn'
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                        />
                        <span className='text-capitalize'> Authenticating</span>
                      </>
                    ) : (
                      "Sign in"
                    )}
                  </Button>
                </Form>

                <div className='d-flex justify-content-center align-items-center mt-4'>
                  <span className='fw-normal'>
                    Not registered?
                    <Card.Link
                      as={Link}
                      to={Routes.Signup.path}
                      className='fw-bold'
                    >
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

Signin.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login })(Signin);
