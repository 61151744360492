import React, { useState } from "react";
import { Button, FormControl } from "react-bootstrap";
// import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
// import { setFilterTextGlobal } from "../../../features/global/globalSlice";
const FilterComponent = ({ placeholder, onFilter, onResetFilter }) => {
  const [filterText, setFilterText] = useState("");

//   const dispatch = useDispatch();

  const onClear = () => setFilterText("");

//   const onChange = (e) => {
//     setFilterText(e.target.value);
//     dispatch(setFilterTextGlobal(e.target.value));
//   };

  return (
    <>
      <div className='container-fluid'>
        <div className='d-flex flex-column flex-md-row justify-content-start justify-content-xl-end'>
          <div className='d-flex me-2 mb-2 mb-md-0'>
            <FormControl
              id='search'
              type='text'
              placeholder={placeholder}
              style={filterFieldStyle}
              value={filterText}
            //   onChange={onChange}
            />
            <Button
              variant='success'
              style={searchButtonStyle}
              onClick={() => {
                if (filterText === "") return toast.error("Enter search query");
                onFilter(filterText);
              }}
            >
              Search
            </Button>
          </div>
          <Button
            variant='danger'
            style={resetButtonStyle}
            // disabled={filterText === "" ? true : false}
            onClick={() => {
              setFilterText("");
              onResetFilter();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    </>
  );
};

const searchButtonStyle = {
  // border: "1px solid #2f2f55",
  boxShadow: "none",
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: "5px",
  borderBottomRightRadius: "5px",
  cursor: "pointer",
};

const resetButtonStyle = {
  // border: "1px solid #2f2f55",
  boxShadow: "none",
  cursor: "pointer",
};

const filterFieldStyle = {
  // border: "none",
  width: "300px",
  boxShadow: "none",
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: "5px",
  borderTopLeftRadius: "5px",
};

export default FilterComponent;
