import React, { useState, useEffect } from "react";
import { Route, Routes, Redirect, Navigate, Outlet } from "react-router-dom";
import { Routes as Router } from "../routes";
import { useDispatch } from "react-redux";

// pages
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Products from "./Products";
import Roles from "./Roles";
import Departments from "./tables/Departments/Departments";
import Setup from "./tables/Setup/Setup";
import Teams from "./tables/Teams/Teams";
import Leads from "./Leads";
import Deals from "./Deals";
import Records from "./Records";
import Users from "./Users";
import Settings from "./Settings";
import UserDeals from "../components/UserDeals/UserDeals";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./auth/Signin";
import Signup from "./auth/Signup";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import Lock from "./auth/Lock";
import NotFoundPage from "./error/NotFound";
import ServerError from "./error/ServerError";
import PrivateRoutes from "./PrivateRoutes";

import { loadUser } from "../actions/authActions";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";

const RouteWithLoader = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []); 

  return (
    <>
      <Preloader show={loaded ? false : true} />
      <Outlet />
    </>
  );
};

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  return (
    <Routes>
      <Route path="" element={<PrivateRoutes />}>
        <Route path={Router.Accordions.path} element={<Accordion />} />
        <Route path={Router.Alerts.path} element={<Alerts />} />
        <Route path={Router.Badges.path} element={<Badges />} />
        <Route path={Router.Breadcrumbs.path} element={<Breadcrumbs />} />
        <Route path={Router.Buttons.path} element={<Buttons />} />
        <Route path={Router.Forms.path} element={<Forms />} />
        <Route path={Router.Modals.path} element={<Modals />} />
        <Route path={Router.Navs.path} element={<Navs />} />
        <Route path={Router.Navbars.path} element={<Navbars />} />
        <Route path={Router.Pagination.path} element={<Pagination />} />
        <Route path={Router.Popovers.path} element={<Popovers />} />
        <Route path={Router.Progress.path} element={<Progress />} />
        <Route path={Router.Tables.path} element={<Tables />} />
        <Route path={Router.Tabs.path} element={<Tabs />} />
        <Route path={Router.Tooltips.path} element={<Tooltips />} />
        <Route path={Router.Toasts.path} element={<Toasts />} />
        <Route
          path={Router.DashboardOverview.path}
          element={<DashboardOverview />}
        />
        <Route path={Router.Upgrade.path} element={<Upgrade />} />
        <Route path={Router.Transactions.path} element={<Transactions />} />
        <Route path={Router.Setup.path} element={<Setup />} />
        {/* <Route path={Router.Leads.path} element={<Leads />} /> */}
        <Route path={Router.Teams.path} element={<Teams />} />
        <Route path={Router.Records.path} element={<Records />} />
        <Route path={Router.Deals.path} element={<Deals />} />
        <Route path={Router.Users.path} element={<Users />} />
        <Route path={Router.UserDeals.path} element={<UserDeals />} />
        <Route path={Router.Settings.path} element={<Settings />} />
        <Route
          path={Router.BootstrapTables.path}
          element={<BootstrapTables />}
        />
      </Route>
      <Route path="" element={<RouteWithLoader />}>
        <Route path={Router.Signin.path} element={<Signin />} />
        <Route path={Router.Signup.path} element={<Signup />} />
        <Route path={Router.ForgotPassword.path} element={<ForgotPassword />} />
        <Route path={Router.ResetPassword.path} element={<ResetPassword />} />
        <Route path={Router.Lock.path} element={<Lock />} />
        <Route path={Router.NotFound.path} element={<NotFoundPage />} />
        <Route path={Router.ServerError.path} element={<ServerError />} />
      </Route>

      <Route
        path="*"
        element={<Navigate replace to={Router.NotFound.path} />}
      />
    </Routes>
  );
};

export default HomePage;
