import axios from "axios";
import toast from "react-hot-toast";
import { apiEndpoint } from "../Global";
import setAuthToken from "../utils/setAuthToken";
import {
  AUTH_ERROR,
  FILTER_USER,
  FILTER_INACTIVE_USER,
  GET_SALES_USERS,
  GET_SUPERVISORS,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_SUCCESS,
  RESET_PASSWORD,
  SET_LOADING,
  UPDATE_USER,
  UPDATE_USER_ROLE,
  USERS_LOADED,
  INACTIVE_USERS_LOADED,
  USER_LOADED,
  VERIFY_OTP,
  VERIFY_PHONE,
} from "./types";

// Load user
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`${apiEndpoint}/user/me`);

    dispatch({
      type: USER_LOADED,
      payload: res.data.data,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: AUTH_ERROR,
      payload: err.response.data,
    });
  }
};

// Load Users
export const loadUsers = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  dispatch(setLoading());

  try {
    const res = await axios.get(`${apiEndpoint}/user/all`);

    // console.log(res.data);

    dispatch({
      type: USERS_LOADED,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: err.response,
    });
  }
};

// Load Users
export const loadInactiveUsers = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  dispatch(setLoading());

  try {
    const res = await axios.get(`${apiEndpoint}/user/inactive-users`);

    // console.log(res.data);

    dispatch({
      type: INACTIVE_USERS_LOADED,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: err.response,
    });
  }
};

// Load Sales Users
export const loadSalesUsers = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  dispatch(setLoading());

  try {
    const res = await axios.get(`${apiEndpoint}/user/get-sales-users`);

    // console.log(res.data);

    dispatch({
      type: GET_SALES_USERS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: err.response,
    });
  }
};

// Load Users
export const loadSupervisors = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`${apiEndpoint}/user/get-supervisors`);

    // const supervisorsToArray = Object.entries(res.data.data).map((key) => ({
    //   ...key[1],
    // }));

    dispatch({
      type: "GET_SUPERVISORS",
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: err.response.data,
    });
  }
};

// Login user
export const login = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({
    type: SET_LOADING,
  });

  try {
    const res = await axios.post(`${apiEndpoint}/login`, formData, config);
    toast.success("Login successful!");

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    if (err.message === "Network Error") {
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
      return toast.error(err.message);
    }
    toast.error(err.response.data.errors);

    dispatch({
      type: AUTH_ERROR,
      payload: err.response.data.errors,
    });
  }
};

// Register user
export const register = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({
    type: SET_LOADING,
  });

  try {
    const res = await axios.post(`${apiEndpoint}/register`, formData, config);
    toast.success("Registration Successful!");

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    errors.forEach((err) => {
      toast.error(err);
    });

    dispatch({
      type: AUTH_ERROR,
      payload: err.response.data.errors,
    });
  }
};

// Assign User Role
export const updateUserRole = (user) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.put(
      `${apiEndpoint}/user/update/${user.id}`,
      user,
      config
    );

    toast.success(res.data.message);
    dispatch({
      type: UPDATE_USER_ROLE,
      payload: res.data.data,
    });
    user.onHide();
  } catch (err) {
    toast.error(err.response.data.errors);

    dispatch({
      type: AUTH_ERROR,
      payload: err.response.data.errors,
    });
  }
};

// Update User
export const updateUser = (user) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.put(
      `${apiEndpoint}/user/update/${user.id}`,
      user,
      config
    );

    toast.success(res.data.message);
    dispatch({
      type: UPDATE_USER,
    });
    dispatch(loadUser());
  } catch (err) {
    toast.err(err.response.data.message);

    dispatch({
      type: AUTH_ERROR,
      payload: err.response.data.message,
    });
  }
};

// Verify phone
export const verifyPhone = (phone) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({
    type: SET_LOADING,
  });

  try {
    const res = await axios.post(`${apiEndpoint}/checknumber`, phone, config);
    toast.success(res.data.message);

    dispatch({
      type: VERIFY_PHONE,
      payload: res.data,
    });
  } catch (err) {
    if (err.message === "Network Error") {
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
      return toast.error(err.errors);
    }
    toast.error(err.response.data.errors);

    dispatch({
      type: AUTH_ERROR,
      payload: err.response.data.errors,
    });
  }
};

// Verify OTP
export const verifyOTP = (otp_code) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({
    type: SET_LOADING,
  });

  try {
    const res = await axios.post(`${apiEndpoint}/checkcode`, otp_code, config);
    toast.success(res.data.message);

    dispatch({
      type: VERIFY_OTP,
      payload: res.data,
    });
  } catch (err) {
    if (err.message === "Network Error") {
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
      return toast.error(err.errors);
    }
    toast.error(err.response.data.errors);

    dispatch({
      type: AUTH_ERROR,
      payload: err.response.data.errors,
    });
  }
};

export const resetPassword = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch(setLoading());

  try {
    const res = await axios.post(`${apiEndpoint}/changepassword`, data, config);

    toast.success(res.data.message);

    dispatch({
      type: RESET_PASSWORD,
      payload: res.data,
    });
    data.navigate("/auth/sign-in");
  } catch (err) {
    toast.error(err.response.data.errors);

    dispatch({
      type: AUTH_ERROR,
      payload: err.response.data.errors,
    });
  }

  dispatch({
    type: RESET_PASSWORD,
  });
};

// FILTER USER
export const filteredUser = (search) => (dispatch) =>
  dispatch({ type: FILTER_USER, payload: search });

  // FILTER INACTIVE USER
export const filterInactiveUsers = (search) => (dispatch) =>
  dispatch({ type: FILTER_INACTIVE_USER, payload: search });


// Log out user
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

// Set Loading
export const setLoading = () => (dispatch) => {
  dispatch({
    type: SET_LOADING,
  });
};
