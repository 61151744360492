import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "react-lottie";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import CurrencyInput from "react-currency-input-field";

import {
  faEllipsisH,
  faEdit,
  faHome,
  faSearch,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Modal,
  Card,
  Table,
  Spinner,
  Badge,
} from "@themesberg/react-bootstrap";
import { formatAmount } from "../Global";
import emptyProduct from "../assets/syntax-themes/93134-not-found";

import { ExportToExcel } from "./ExportDeals";
import { loadUsers } from "../actions/authActions";
import {
  deleteRecord,
  getRecords,
  updateRecord,
  getRecordTypes,
  createRecords,
  getFilteredRecords,
} from "../actions/recordActions";

const Records = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [record, setRecord] = React.useState(null);
  const [recordId, setRecordId] = React.useState("");
  const [search, setSearch] = React.useState("");

  const [recordModalShow, setRecordModalShow] = React.useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { records, recordTypes } = useSelector((state) => state.record);

  React.useEffect(() => {
    dispatch(getRecords());
    dispatch(getRecordTypes());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(loadUsers());
    dispatch(getRecords());
    setModalShow(true);
  }, [dispatch]);

  const clearTextFields = () => {};

  return (
    <>
      {!recordModalShow ? (
        <>
          <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
            <div className='d-block mb-4 mb-md-0'>
              <Breadcrumb
                className='d-none d-md-inline-block'
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                {/* <Breadcrumb.Item active>Records</Breadcrumb.Item> */}
              </Breadcrumb>
              <h4>Records</h4>
              <p className='mb-0'>List of all residence records.</p>
            </div>
            {/* <div className="btn-toolbar mb-2 mb-md-0"> */}
            <ButtonGroup>
              {/* <Button variant="outline-primary" size="sm">
                    Share
                  </Button> */}
            </ButtonGroup>
            {/* </div> */}
            {/* <ExportToExcel apiData={records} fileName={"data"} /> */}
          </div>

          <div className='table-settings mb-4'>
            <Row className='justify-content-between align-items-center'>
              <Col xs={6} md={6} lg={3} xl={4}>
                <div className='d-flex'>
                  <InputGroup className='me-2'>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                      type='text'
                      placeholder='Search'
                      name='search'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </InputGroup>
                  <Button
                    variant='primary'
                    type='button'
                    onClick={() =>
                      search && dispatch(getFilteredRecords({ search }))
                    }
                  >
                    Search
                  </Button>
                </div>
              </Col>
              {user &&
                user.role &&
                (user.role.name === "MD" || user.role.name === "Secretary") && (
                  <Col xs={5} md={2} xl={2}>
                    <Button
                      variant='primary'
                      type='button'
                      className='w-100 text-uppercase'
                      onClick={() => {
                        setModalShow(true);
                        setUpdate(false);
                        clearTextFields();
                      }}
                    >
                      Add Record
                    </Button>
                  </Col>
                )}
            </Row>
          </div>

          <RecordTable
            setModalShow={setModalShow}
            setUpdate={setUpdate}
            setRecord={setRecord}
            dispatch={dispatch}
            onShow={() => setRecordModalShow(true)}
            setRecordId={setRecordId}
          />
          <RecordModal
            dispatch={dispatch}
            show={modalShow}
            update={update}
            recordTypes={recordTypes}
            record={record}
            onHide={() => setModalShow(false)}
            clearTextFields={clearTextFields}
          />
        </>
      ) : null}
    </>
  );
};

export default Records;

const RecordModal = (props) => {
  const [record, setRecord] = React.useState({
    // userId,
    recordTypeId: "",
    client_name: "",
    house_number: "",
    phone_number: "",
    address: "",
    occupation: "",
    currency: "NGN",
  });
  const [product_price, setProductPrice] = React.useState("");
  const { loading } = useSelector((state) => state.record);

  const {
    recordTypeId,
    client_name,
    house_number,
    phone_number,
    address,
    occupation,
    currency,
  } = record;

  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    props.update
      ? dispatch(
          updateRecord({
            id: props.record.id,
            recordTypeId,
            client_name,
            house_number,
            phone_number,
            address,
            occupation,
            product_price,
            currency,
            props,
          })
        )
      : dispatch(
          createRecords({
            recordTypeId,
            client_name,
            house_number,
            phone_number,
            address,
            occupation,
            product_price,
            currency,
            props,
          })
        );
  };

  React.useEffect(() => {
    setRecord({
      userId: props.record && props.record.userId,
      recordTypeId: props.record && props.record.recordTypeId,
      client_name: props.record && props.record.client_name,
      house_number: props.record && props.record.house_number,
      phone_number: props.record && props.record.phone_number,
      address: props.record && props.record.address,
      occupation: props.record && props.record.occupation,
      currency: props.record && props.record.currency,
    });
    setProductPrice(props.record && props.record.product_price);

    if (!props.update) {
      clearTextFields();
    }
  }, [props]);
  const onChange = (e) =>
    setRecord({ ...record, [e.target.name]: e.target.value });

  const clearTextFields = () => {
    setRecord({
      recordTypeId: "",
      client_name: "",
      house_number: "",
      phone_number: "",
      address: "",
      occupation: "",
      product_price: "",
      currency: "NGN",
    });
  };

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {`${props.update ? "Update" : "Add"} Record`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-4'>
            <Form.Label>Select Record Type</Form.Label>
            <Form.Select
              name='recordTypeId'
              value={recordTypeId}
              onChange={onChange}
            >
              <option value=''>Choose Record</option>
              {props.recordTypes.map((rc) => (
                <option key={rc.id} value={rc.id}>
                  {rc.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='client_name'
                value={client_name}
                // maxLength={11}
                placeholder='Client Name'
                onChange={onChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='house_number'
                value={house_number}
                placeholder='House Number'
                onChange={onChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='phone_number'
                value={phone_number}
                placeholder='Phone Number'
                onChange={onChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='address'
                value={address}
                placeholder='Address'
                onChange={onChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <Form.Control
                autoFocus
                type='text'
                name='occupation'
                value={occupation}
                placeholder='Occupation'
                onChange={onChange}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group className='mb-4'>
            <Form.Label>Currency</Form.Label>
            <Form.Select name='currency' value={currency} onChange={onChange}>
              <option value=''>Select Currency</option>
              <option value='NGN'>NGN</option>
              <option value='USD'>USD</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-4'>
            <InputGroup>
              <CurrencyInput
                className='form-control'
                placeholder='Enter Investment Amount'
                prefix={currency === "NGN" ? "₦" : "$"}
                name='product_price'
                value={product_price}
                decimalsLimit={2}
                onValueChange={(value) => setProductPrice(value)}
              />
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='text-white text-uppercase btn-block'
            // disabled={productId ? false : true}
            variant='primary'
            type='submit'
          >
            {loading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' />
                <span className='text-capitalize'> Submitting</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const RecordTable = (props) => {
  // const [currentPage, setCurrentPage] = React.useState(0);
  // const [dealPerPage] = React.useState(10);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyProduct,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const dispatch = useDispatch();

  const { filtered, records, loading, last_page, current_page } = useSelector(
    (state) => state.record
  );
  const { users, user } = useSelector((state) => state.auth);

  let authUser = user;

  const paginate = ({ selected }) => {
    //   if (status === "Open") {
    //     dispatch(
    //       filteredDealByTypeOpen({
    //         num: selected,
    //         type: "Investment",
    //         status: "Open",
    //       })
    //     );
    //   } else if (status === "Closed") {
    //     dispatch(
    //       filteredDealByTypeOpen({
    //         num: selected,
    //         type: "Investment",
    //         status: "Closed",
    //       })
    //     );
    //   } else {
    //     dispatch(getDeals(selected));
    //   }
  };

  const TableRow = (props) => {
    const {
      id,
      client_name,
      house_number,
      phone_number,
      address,
      occupation,
      product_price,
      currency,
      created_at,
      record_type,
    } = props;

    const onVerifyDelete = (id) => {
      swal({
        title: "Delete Record",
        text: "Do you want to delete? ",
        icon: "warning",
        buttons: ["Cancel", true],
        dangerMode: true,
      }).then((result) => {
        if (result) {
          dispatch(deleteRecord(id));
        }
      });
    };

    return (
      <tr>
        <td>
          <span className='fw-normal'>{client_name}</span>
        </td>

        <td>
          <span className='fw-normal'>{house_number}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone_number}</span>
        </td>
        <td>
          <span className='fw-normal'>{address}</span>
        </td>
        <td>
          <span className='fw-normal'>{occupation}</span>
        </td>
        <td>
          <span className='fw-normal'>{currency}</span>
        </td>
        <td>
          <span className='fw-normal'>{record_type.name}</span>
        </td>
        <td>
          <span className='fw-normal'>{record_type.location}</span>
        </td>
        <td>
          <span className='fw-normal'>
            {formatAmount(product_price, currency)}
          </span>
        </td>

        <td>
          <span className='fw-normal'>
            {moment.utc(created_at).format("ll")}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant='link'
              className='text-dark m-0 p-0'
            >
              <span className='icon icon-sm'>
                <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {authUser &&
                authUser.role &&
                (authUser.role.name !== "Sales Executive" ||
                  authUser.role.name !== "Account Officer") && (
                  <Dropdown.Item
                    onClick={() => {
                      props.setModalShow(true);
                      props.setUpdate(true);
                      props.setRecord(props);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit
                  </Dropdown.Item>
                )}
              {authUser && authUser.role && authUser.role.name === "MD" && (
                <Dropdown.Item
                  onClick={() => {
                    onVerifyDelete(id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} className='me-2' /> Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {records && records.length > 0 ? (
        <>
          {!loading ? (
            <Card
              border='light'
              className='table-wrapper table-responsive shadow-sm'
            >
              <Card.Body className='pt-0'>
                <Table
                  id='table-to-xls'
                  hover
                  className='user-table align-items-center'
                >
                  <thead>
                    <tr>
                      <th className='border-bottom'>Client Name</th>
                      <th className='border-bottom'>House Number</th>
                      <th className='border-bottom'>Phone Number</th>
                      <th className='border-bottom'>Address</th>
                      <th className='border-bottom'>Occupation</th>
                      <th className='border-bottom'>Currency</th>
                      <th className='border-bottom'>Location</th>
                      <th className='border-bottom'>Product Price</th>
                      <th className='border-bottom'>Location</th>
                      <th className='border-bottom'>Created Date</th>
                      <th className='border-bottom'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records &&
                      records.map((record) => (
                        <TableRow
                          key={record.id}
                          {...record}
                          setModalShow={props.setModalShow}
                          setUpdate={props.setUpdate}
                          setRecord={props.setRecord}
                          showModal={props.onShow}
                        />
                      ))}
                  </tbody>
                </Table>

                <div className='my-3' />
                {/* <Card.Footer className="border-0 d-lg-flex align-items-center justify-content-between"></Card.Footer> */}
              </Card.Body>
              <div className='paginate_container mx-auto'>
                <ReactPaginate
                  previousLabel='Prev'
                  nextLabel='Next'
                  pageCount={last_page}
                  onPageChange={paginate}
                  containerClassName={"paginationBtns"}
                  previousLinkClassName={"previousBtn"}
                  nextLinkClassName={"nextBtn"}
                  disabledClassName={"disabledBtn"}
                  activeClassName={"activeBtn"}
                />
              </div>
            </Card>
          ) : (
            <Lottie options={defaultOptions} height={300} width={300} />
          )}
        </>
      ) : (
        <Lottie options={defaultOptions} height={300} width={300} />
      )}
    </>
  );
};
