import React from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  Dropdown,
  ProgressBar,
  Pagination,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faEdit,
  faEllipsisH,
  faExternalLinkAlt,
  faEye,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";

import { useDispatch } from "react-redux";
import { setCurrent, clearCurrent } from "../../../actions/departmentActions";
import { deleteLocation } from "../../../actions/locationActions";

import LocationModal from "./LocationModal";

const LocationItem = ({ location }) => {
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = React.useState(false);

  const { id, name, created_at } = location;

  const onDelete = () => {
    swal({
      title: "Delete Location",
      text: `Are you sure you want to delete ${location.name}? `,
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((result) => {
      if (result) {
        dispatch(deleteLocation(location));
      }
    });
  };

  return (
    <>
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold text-uppercase">
            {name}
          </Card.Link>
        </td>
        <td className="">{moment.utc(created_at).format("ll")}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  dispatch(setCurrent(location));
                  setModalShow(!modalShow);
                }}
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => onDelete()}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>

      <LocationModal
        show={modalShow}
        onHide={() => {
          setModalShow(!modalShow);
        }}
      />
    </>
  );
};

export default LocationItem;
