import {
  LOADING_RECORDS,
  GET_RECORDS,
  GET_RECORDTYPE,
  EXPORT_RECORDS,
  GET_DASHBOARD_DETAILS,
  UPDATE_RECORD,
  DELETE_RECORD,
  DELETE_RECORDTYPE,
  FILTER_RECORD,
  FILTER_RECORDTYPE,
  RECORD_ERROR,
  ADD_RECORD,
  RECORD_TYPES,
} from "../actions/types";

const INITIALSTATE = {
  recordTypes: [],
  records: [],
  excel: null,
  total: null,
  next_page: "",
  previous_page: "",
  last_page: 0,
  per_page: 0,
  total_pages: 0,
  filtered: null,
  loading: false,
  error: null,
};

const recordReducer = (state = INITIALSTATE, action) => {
  const { type, payload, status } = action;
  switch (type) {
    case LOADING_RECORDS:
      return {
        ...state,
        loading: true,
      };
    case GET_RECORDS:
      return {
        ...state,
        records: payload.data,
        next_page: payload.next_page_url,
        previous_page: payload.previous_page_url,
        last_page: payload.last_page,
        per_page: payload.per_page,
        total_pages: payload.total,
        status: status,
        loading: false,
      };
    case GET_RECORDTYPE:
      return {
        ...state,
        recordTypes: payload,
        loading: false,
      };
    case EXPORT_RECORDS:
      return {
        ...state,
        excel: payload.data,
        loading: false,
      };
    case GET_DASHBOARD_DETAILS:
      return {
        ...state,
        total: payload.total,
        loading: false,
      };
    case ADD_RECORD:
      return {
        ...state,
        records: payload.data,
        next_page: payload.next_page_url,
        previous_page: payload.previous_page_url,
        last_page: payload.last_page,
        per_page: payload.per_page,
        total_pages: payload.total,
        status: status,
        loading: false,
      };
    case UPDATE_RECORD:
      return {
        ...state,
        // deals: state.deals.map((deal) =>
        //   deal.id === payload.id ? payload : deal
        // ),
        loading: false,
      };
    case FILTER_RECORDTYPE:
      return {
        ...state,
        filtered: state.records.filter((record) => {
          const regex = new RegExp(`${payload}`, "gi");
          return record.status.match(regex);
        }),
        loading: false,
      };
    case DELETE_RECORD:
      return {
        ...state,
        records: state.records.filter((record) => record.id !== payload),
        loading: false,
      };
    case DELETE_RECORDTYPE:
      return {
        ...state,
        recordTypes: state.recordTypes.filter(
          (record) => record.id !== payload
        ),
        loading: false,
      };
    case RECORD_TYPES:
      return {
        ...state,
        recordTypes: [...payload, state.recordTypes],
        // recordTypes: state.records.filter((record) => record.id !== payload),
        loading: false,
      };
    case RECORD_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default recordReducer;
