import {
  LOADING_RECORDS,
  GET_RECORDS,
  GET_RECORDTYPE,
  EXPORT_RECORDS,
  GET_DASHBOARD_DETAILS,
  UPDATE_RECORD,
  UPDATE_RECORDTYPE,
  DELETE_RECORD,
  DELETE_RECORDTYPE,
  FILTER_RECORD,
  RECORD_ERROR,
  RECORDTYPE_ERROR,
  RECORD_TYPES,
  FILTER_RECORDTYPE,
  ADD_RECORD,
} from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { apiEndpoint } from "../Global";
import toast from "react-hot-toast";

// Load Records
export const getRecords = (num) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    // dispatch({ type: LOADING_DEAL });
    const res = await axios.get(`${apiEndpoint}/records?page=${num + 1}`);

    dispatch({
      type: GET_RECORDS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: RECORD_ERROR,
      payload: err.response.data,
    });
  }
};

// Get Record TYpes
export const getRecordTypes = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`${apiEndpoint}/recordType`);

    dispatch({
      type: GET_RECORDTYPE,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: RECORD_ERROR,
      payload: err.response.data,
    });
  }
};

// Load Records Count
export const getRecordsCounts = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    // dispatch({ type: LOADING_DEAL });
    const res = await axios.get(`${apiEndpoint}/deals/dashboard`);

    dispatch({
      type: GET_DASHBOARD_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RECORD_ERROR,
      payload: err.response.data,
    });
  }
};

// Load Filtered Records
export const getFilteredRecords = (search) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_RECORDS });
    const res = await axios.post(
      `${apiEndpoint}/records/filter_record`,
      search
    );

    dispatch({
      type: ADD_RECORD,
      payload: res.data.data,
    });
    // filter.setModalShow(false);
  } catch (err) {
    dispatch({
      type: RECORD_ERROR,
      payload: err.response.data,
    });
  }
};

// Create Record
export const createRecords = (record) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_RECORDS });
    const res = await axios.post(
      `${apiEndpoint}/records/create`,
      record,
      config
    );

    dispatch(getRecords());
    toast.success(res.data.message);
    record.props.onHide();
  } catch (err) {
    dispatch({
      type: RECORD_ERROR,
      // payload: err.response.data,
    });
  }
};

// UPDATE RECORD
export const updateRecord = (record) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_RECORDS });
    const res = await axios.put(
      `${apiEndpoint}/records/update/${record.id}`,
      record,
      config
    );

    dispatch({
      type: UPDATE_RECORD,
      // payload: res.data.data,
    });
    toast.success(res.data.message);
    dispatch(getRecords());
    record.props.onHide();
  } catch (err) {
    dispatch({
      type: RECORD_ERROR,
      // payload: err.response.data.message,
    });
  }
};

// Create RecordType
export const createRecordType = (recordType) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_RECORDS });

    const res = await axios.post(
      `${apiEndpoint}/recordType/create`,
      recordType,
      config
    );

    dispatch(getRecordTypes());
    toast.success(res.data.message);
    recordType.props.onHide();
  } catch (err) {
    dispatch({
      type: RECORDTYPE_ERROR,
      // payload: err.response.data,
    });
  }
};

// UPDATE RECORDTYPE
export const updateRecordType = (recordType) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_RECORDS });
    const res = await axios.put(
      `${apiEndpoint}/recordType/update/${recordType.id}`,
      recordType,
      config
    );

    dispatch({
      type: UPDATE_RECORDTYPE,
      // payload: res.data.data,
    });
    toast.success(res.data.message);
    dispatch(getRecordTypes());
    recordType.props.onHide();
  } catch (err) {
    dispatch({
      type: RECORDTYPE_ERROR,
      // payload: err.response.data.message,
    });
  }
};

export const exportToExcel = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`${apiEndpoint}/deals/exporttoexcel`);

    dispatch({
      type: EXPORT_RECORDS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: RECORD_ERROR,
      // payload: err.response.data.message,
    });
  }
};

// FILTER RECORD
export const filteredTypeRecords = (search) => (dispatch) =>
  dispatch({ type: FILTER_RECORDTYPE, payload: search });

// DELETE RECORD
export const deleteRecord = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_RECORDS });
    const res = await axios.delete(`${apiEndpoint}/records/delete/${id}`);

    dispatch({
      type: DELETE_RECORD,
      payload: id,
    });
    toast.success(res.data.message);
  } catch (err) {
    dispatch({
      type: RECORD_ERROR,
      payload: err.response.data,
    });
  }
};

// DELETE RECORDTYPE
export const deleteRecordType = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_RECORDS });
    const res = await axios.delete(`${apiEndpoint}/recordType/delete/${id}`);

    dispatch({
      type: DELETE_RECORDTYPE,
      payload: id,
    });
    toast.success(res.data.message);
  } catch (err) {
    dispatch({
      type: RECORD_ERROR,
      payload: err.response.data,
    });
  }
};
