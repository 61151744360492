import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CurrencyInput from "react-currency-input-field";
import Select from "react-select";
import { createDeal, updateDeal } from "../actions/dealActions";

import {
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "@themesberg/react-bootstrap";

const DealModal = (props) => {
  const [deal, setDeal] = useState({
    userId: "",
    productId: "",
    locationId: "",
    reassignedToId: "",
    status: "",
    customer: "",
    company: "",
    position: "",
    phone: "",
    email: "",
    remark: "",
    currency: "NGN",
  });
  const [investment_amount, setInvestmentAmount] = useState("");
  const [productId, setProductId] = useState(null);
  const [product, setProduct] = useState(null);
  const [dropDown, setDropDown] = useState(null);
  const { loading } = useSelector((state) => state.deal);
  const { authLoader } = useSelector((state) => state.auth);
  const { users, salesUsers, user } = useSelector((state) => state.auth);
  const { locations } = useSelector((state) => state.location);
  const { products } = useSelector((state) => state.product);

  const {
    userId,
    locationId,
    reassignedToId,
    status,
    customer,
    company,
    position,
    phone,
    email,
    currency,
    remark,
    teleSalesRemark,
  } = deal;

  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();

    // console.log(deal);

    props.update
      ? dispatch(
          updateDeal({
            id: props.deal.id,
            reassignedToId,
            userId,
            customer,
            company,
            position,
            phone,
            email,
            productId,
            locationId,
            status,
            onHide: props.onHide,
            user,
            currency,
            remark,
            teleSalesRemark,
            investment_amount,
          })
        )
      : dispatch(
          createDeal({
            userId,
            assignedById: user.id,
            reassignedToId,
            customer,
            company,
            position,
            phone,
            email,
            productId,
            locationId,
            status,
            onHide: props.onHide,
            user,
            currency,
            remark,
            teleSalesRemark,
            investment_amount,
          })
        );

    // }
  };

  useEffect(() => {
    setDeal({
      userId: props.deal && props.deal.userId,
      locationId: props.deal && props.deal.locationId,
      productId: props.deal && props.deal.productId,
      reassignedToId: props.deal && props.deal.reassignedToId,
      status: props.deal && props.deal.status,
      customer: props.deal && props.deal.customer,
      company: props.deal && props.deal.company,
      position: props.deal && props.deal.position,
      phone: props.deal && props.deal.phone,
      email: props.deal && props.deal.email,
      currency: props.deal && props.deal.currency,
      remark: props.remark && props.deal.remark,
      teleSalesRemark: props.teleSalesRemark && props.deal.teleSalesRemark,
    });
    setProductId(props.deal && props.deal.productId);
    // setDropDown(props.deal && props.deal.product.type);
    setInvestmentAmount(props.deal && props.deal.investment_amount);
    if (!props.update) {
      clearTextFields();
    }
  }, [props]);

  const onChange = (e) => setDeal({ ...deal, [e.target.name]: e.target.value });

  const [values, setValues] = useState([]);

  const clearTextFields = () => {
    setDeal({
      userId: "",
      productId: "",
      locationId: "",
      reassignedToId: null,
      status: "",
      customer: "",
      company: "",
      position: "",
      phone: "",
      email: "",
      remark: "",
      currency: "NGN",
    });
    setDropDown("");
    setProductId("");
  };

  const reAssignedToDropDown = users
    .filter(
      (user) =>
        user.role &&
        user.role.name !== "Admin" &&
        user.role.name !== "MD" &&
        user.role.name !== "Account Officer"
    )
    .map((user) => {
      return { value: user.id, label: `${user.firstname} ${user.lastname}` };
    });

  const assignedToDropDown = salesUsers.map((user) => {
    return { value: user.id, label: `${user.firstname} ${user.lastname}` };
  });

  // useEffect(() => {
  //   console.log(users);
  // }, [users]);

  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      keyboard={false}
      backdrop='static'
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {`${props.update ? "Update" : "Add"} Lead`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='deal-modal'>
          {authLoader ? (
            <div className='container-fluid d-flex align-items-center justify-content-center'>
              <p>Preparing Form Components...</p>
            </div>
          ) : (
            <>
              <Form.Group className='mb-4'>
                <InputGroup>
                  <Form.Control
                    autoFocus
                    type='text'
                    name='customer'
                    value={customer}
                    placeholder='Customer Name'
                    onChange={onChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className='mb-4'>
                <InputGroup>
                  <Form.Control
                    autoFocus
                    type='text'
                    name='phone'
                    value={phone}
                    // maxLength={11}
                    disabled={props.update ? true : false}
                    placeholder='Customer Phone'
                    onChange={onChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className='mb-4'>
                <InputGroup>
                  <Form.Control
                    autoFocus
                    type='email'
                    name='email'
                    value={email}
                    placeholder='Email Address (optional)'
                    onChange={onChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className='mb-4'>
                <InputGroup>
                  <Form.Control
                    autoFocus
                    type='text'
                    name='company'
                    value={company}
                    placeholder='Customer Company'
                    onChange={onChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className='mb-4'>
                <InputGroup>
                  <Form.Control
                    autoFocus
                    type='text'
                    name='position'
                    value={position}
                    placeholder='Customer Position'
                    onChange={onChange}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group className='mb-4'>
                <Form.Label>Location</Form.Label>
                <Form.Select
                  name='locationId'
                  value={locationId}
                  onChange={onChange}
                >
                  <option value=''>Choose Location</option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              {user &&
                user.role &&
                (user.role.name === "PV" ||
                  user.role.name === "Sales Executive") && (
                  <Form.Group className='mb-4'>
                    <Form.Label>Supervisor</Form.Label>
                    <Form.Select
                      name='userId'
                      value={userId}
                      onChange={onChange}
                    >
                      <option value=''>Choose your Supervisor</option>
                      {users
                        .filter(
                          (user) => user.role && user.role.name === "Sales Lead"
                        )
                        .map((user) => (
                          <option key={user.id} value={user.id}>
                            {`${user.firstname} ${user.lastname}`}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                )}

              {user &&
                user.role &&
                (user.role.name === "Sales Lead" ||
                  user.role.name === "Admin") && (
                  <Form.Group className='mb-4'>
                    <Form.Label>Reassigned To</Form.Label>
                    <Select
                      options={reAssignedToDropDown}
                      onChange={(value) =>
                        setDeal({ ...deal, reassignedToId: value.value })
                      }
                    />
                    {/* <Form.Select
                      name='reassignedToId'
                      value={reassignedToId}
                      onChange={onChange}
                    >
                      <option value=''>Select</option>
                      {users
                        .filter(
                          (user) =>
                            user.role &&
                            user.role.name !== "Admin" &&
                            user.role.name !== "MD" &&
                            user.role.name !== "Account Officer"
                        )
                        .map((user) => (
                          <option key={user.id} value={user.id}>
                            {`${user.firstname} ${user.lastname}`}
                          </option>
                        ))}
                    </Form.Select> */}
                  </Form.Group>
                )}

              {user &&
                user.role &&
                (user.role.name === "MD" ||
                  user.role.name === "Account Officer" ||
                  user.role.name === "Sales Lead") && (
                  <Form.Group className='mb-4'>
                    <Form.Label>Assigned To</Form.Label>
                    <Select
                      options={assignedToDropDown}
                      onChange={(value) =>
                        setDeal({ ...deal, userId: value.value })
                      }
                    />
                    {/* <Form.Select
                      name='userId'
                      value={userId}
                      onChange={onChange}
                      disabled={
                        props && props.deal && props.deal.status === "Close" && true
                      }
                    >
                      <option value=''>Choose a Staff</option>
                      {users
                        .filter(
                          (user) =>
                            user.department && user.department.name === "Sales"
                    console.log(e.target.value)
                        )
                        .map((user) => (
                          <option key={user.id} value={user.id}>
                            {`${user.firstname} ${user.lastname}`}
                          </option>
                        ))}
                    </Form.Select> */}
                  </Form.Group>
                )}

              <Form.Group className='mb-4'>
                <Form.Label>Product</Form.Label>
                <Form.Select
                  name='productId'
                  value={productId}
                  onChange={(e) => {
                    setProductId(e.target.value);
                    setProduct(
                      products.find((product) => product.id === e.target.value)
                    );
                  }}
                  disabled={
                    props && props.deal && props.deal.status === "Close" && true
                  }
                >
                  <option value=''>Choose a Product</option>
                  {products.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              {product && product.type === "Investment" && (
                <>
                  <Form.Group className='mb-4'>
                    <Form.Label>Currency</Form.Label>
                    <Form.Select
                      name='currency'
                      value={currency}
                      onChange={onChange}
                    >
                      <option value=''>Select Currency</option>
                      <option value='NGN'>NGN</option>
                      <option value='USD'>USD</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <InputGroup>
                      <CurrencyInput
                        className='form-control'
                        placeholder='Investment Amount'
                        prefix={currency === "NGN" ? "₦" : "$"}
                        name='investment_amount'
                        value={investment_amount}
                        decimalsLimit={2}
                        onValueChange={(value) => setInvestmentAmount(value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </>
              )}
              {dropDown === "Investment" && (
                <>
                  <Form.Group className='mb-4'>
                    <Form.Label>Currency</Form.Label>
                    <Form.Select
                      name='currency'
                      value={currency}
                      onChange={onChange}
                    >
                      <option value=''>Select Currency</option>
                      <option value='NGN'>NGN</option>
                      <option value='USD'>USD</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <InputGroup>
                      <CurrencyInput
                        className='form-control'
                        placeholder='Investment Amount'
                        prefix={currency === "NGN" ? "₦" : "$"}
                        name='investment_amount'
                        value={investment_amount}
                        decimalsLimit={2}
                        onValueChange={(value) => setInvestmentAmount(value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </>
              )}
              {props.update && (
                <Form.Group className='mb-4'>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name='status'
                    value={status}
                    onChange={onChange}
                    disabled={
                      user &&
                      user.role &&
                      (user.role.name === "Sales Lead" ||
                        user.role.name === "PV" ||
                        user.role.name === "Sales Executive") &&
                      true
                    }
                  >
                    <option value=''>Choose a Status</option>
                    <option value='Open'>Open</option>
                    <option value='Close'>Close</option>
                  </Form.Select>
                </Form.Group>
              )}
              {user.role.name === "Telesales" ||
              user.role.name === "MD" ||
              user.role.name === "Admin" ||
              user.role.name === "Supervisor" ? (
                <Form.Group className='mb-4'>
                  <InputGroup>
                    <Form.Control
                      autoFocus
                      as='textarea'
                      rows={3}
                      name='teleSalesRemark'
                      value={teleSalesRemark}
                      placeholder='Telesales Remark'
                      onChange={onChange}
                    />
                  </InputGroup>
                </Form.Group>
              ) : (
                <div style={{ display: "none" }}></div>
              )}

              {user.role.name !== "Telesales" && (
                <Form.Group className='mb-4'>
                  <InputGroup>
                    <Form.Control
                      autoFocus
                      as='textarea'
                      rows={3}
                      name='remark'
                      value={remark}
                      placeholder='Remark'
                      onChange={onChange}
                    />
                  </InputGroup>
                </Form.Group>
              )}
            </>
          )}
        </Modal.Body>
        {!authLoader && (
          <Modal.Footer>
            <Button
              className='text-white text-uppercase btn-block border-0 shadow-none'
              disabled={productId && userId !== "" ? false : true}
              variant='primary'
              type='submit'
            >
              {loading ? (
                <>
                  <Spinner animation='border' size='sm' />
                  {/* <span className='text-capitalize'> Submitting</span> */}
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
};

export default DealModal;
