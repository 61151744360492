import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect, useSelector, useDispatch } from "react-redux";
import {
  createLocation,
  updateLocation,
} from "../../../actions/locationActions";

import Alert from "../../../components/Alert";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "@themesberg/react-bootstrap";

const LocationModal = ({
  loading,
  createLocation,
  updateLocation,
  show,
  onHide,
  update,
}) => {
  const [location, setLocation] = React.useState({
    name: "",
  });

  const { current } = useSelector((state) => state.department);

  useEffect(() => {
    if (current) {
      setLocation({ name: current.name });
    } else {
      setLocation({ name: "" });
    }
  }, [current]);

  const { name } = location;

  const onSubmit = (e) => {
    e.preventDefault();
    current
      ? updateLocation({ id: current.id, name, onHide })
      : createLocation({ name, onHide });
  };

  const onChange = (e) =>
    setLocation({ ...location, [e.target.name]: e.target.value });

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {current ? "Update Location" : "Add Location"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-4">
            <InputGroup>
              <Form.Control
                autoFocus
                type="text"
                name="name"
                value={name}
                placeholder="Enter Location Name"
                onChange={onChange}
              />
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="text-white text-uppercase btn-block"
            variant="primary"
            disabled={name ? false : true}
            type="submit"
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                ></Spinner>
                <span className="text-capitalize"> Submitting</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

LocationModal.propTypes = {
  createLocation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  locations: state.location.locations,
  loading: state.location.loading,
});
export default connect(mapStateToProps, { createLocation, updateLocation })(
  LocationModal
);
