import {
  LOADING_DEAL,
  GET_DEALS,
  EXPORT_DEALS,
  GET_DASHBOARD_DETAILS,
  ADD_DEAL,
  UPDATE_DEAL,
  DELETE_DEAL,
  FILTER_DEAL,
  DEAL_ERROR,
  GET_USER_DEALS,
} from "../actions/types";

const INITIALSTATE = {
  deals: [],
  property_Inspection: [],
  investment_Inspection: [],
  currentUserDeals: [],
  totalOpenDeals: null,
  totalCloseDeals: null,
  noCloseDeals: null,
  openPropertyAmount: null,
  closedPropertyAmount: null,
  openInvestmentAmount: null,
  closedInvestmentAmount: null,
  excel: null,
  status: "",
  today: null,
  week: null,
  month: null,
  total: null,
  next_page: "",
  previous_page: "",
  current_page: "",
  last_page: 0,
  per_page: 0,
  total_pages: 0,
  filtered: null,
  dealLoader: false,
  error: null,
};

const dealReducer = (state = INITIALSTATE, action) => {
  const { type, payload, status } = action;
  switch (type) {
    case LOADING_DEAL:
      return {
        ...state,
        dealLoader: true,
      };
    case GET_DEALS:
      return {
        ...state,
        deals: payload.data,
        next_page: payload.next_page_url,
        previous_page: payload.previous_page_url,
        last_page: payload.last_page,
        per_page: payload.per_page,
        total_pages: payload.total,
        status: status,
        dealLoader: false,
        current_page: payload.currentPage,
      };
    case EXPORT_DEALS:
      return {
        ...state,
        excel: payload.data,
        dealLoader: false,
      };
    case GET_DASHBOARD_DETAILS:
      return {
        ...state,
        today: payload.today,
        week: payload.week,
        month: payload.month,
        property_Inspection: payload.property_Inspection,
        investment_Inspection: payload.investment_Inspection,
        totalOpenDeals: payload.totalOpenDeals,
        totalCloseDeals: payload.totalCloseDeals,
        openPropertyAmount: payload.openPropertyAmount,
        closedPropertyAmount: payload.closedPropertyAmount,
        openInvestmentAmount: payload.openInvestmentAmount,
        closedInvestmentAmount: payload.closedInvestmentAmount,
        noCloseDeals: payload.noOfCloseDeals,
        total: payload.total,
        dealLoader: false,
      };
    case ADD_DEAL:
      return {
        ...state,
        // deals: [payload, ...state.deals],
        dealLoader: false,
      };
    case UPDATE_DEAL:
      return {
        ...state,
        // deals: state.deals.map((deal) =>
        //   deal.id === payload.id ? payload : deal
        // ),
        dealLoader: false,
      };
    case FILTER_DEAL:
      return {
        ...state,
        deals: payload.data,
        next_page: payload.next_page_url,
        previous_page: payload.previous_page_url,
        last_page: payload.last_page,
        per_page: payload.per_page,
        total_pages: payload.total,
        status: status,
        dealLoader: false,
      };
    case DELETE_DEAL:
      return {
        ...state,
        deals: state.deals.filter((deal) => deal.id !== payload),
        dealLoader: false,
      };
    case DEAL_ERROR:
      return {
        ...state,
        error: payload,
        dealLoader: false,
      };
    case GET_USER_DEALS:
      return {
        ...state,
        dealLoader: false,
        currentUserDeals: payload,
      };
    default:
      return state;
  }
};

export default dealReducer;
