import React from "react";

const CustomLoader = () => {
  return (
    <div className='d-flex align-items-center justify-content-center w-100 h-100'>
      <p className='fw-bold fs-6'>Refreshing Data...</p>
    </div>
  );
};

export default CustomLoader;
