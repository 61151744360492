import { LOADING_ACTIVITY, GET_ACTIVITIES, ACTIVITY_ERROR } from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { apiEndpoint } from "../Global";

// Load Activities
export const getActivities = (num) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_ACTIVITY });
    const res = await axios.get(`${apiEndpoint}/activities?page=${num + 1}`);

    dispatch({
      type: GET_ACTIVITIES,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: ACTIVITY_ERROR,
      payload: err.response.data,
    });
  }
};
