import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faPhoneAlt,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Spinner,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";

// components
import Alert from "../../components/Alert";
import { Routes } from "../../routes";

import {
  verifyPhone,
  verifyOTP,
  resetPassword,
} from "../../actions/authActions";

const ResetPassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { loading, phoneExists, isValidOTP } = useSelector(
    (state) => state.auth
  );

  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  var isMatchRef = useRef(false);
  const confirmPasswordRef = useRef();

  const onVerifyPhone = (e) => {
    e.preventDefault();
    if (phone.length < 11) {
      Alert("Please include a valid phone number", "error");
    } else {
      dispatch(verifyPhone({ phone }));
    }
  };

  const onConfirmOTP = (e) => {
    e.preventDefault();
    dispatch(verifyOTP({ otp_code: otp }));

    // clear OTP
    setOtp("");
  };

  const onResetPassword = (e) => {
    e.preventDefault();

    if (password === "" || confirmPassword === "") {
      Alert("Password is required!", "error");
    } else {
      dispatch(resetPassword({ phone, password, navigate }));
    }

    // clear fields
    setPhone("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <main>
      <section className='bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
        <Container>
          <Row className='justify-content-center'>
            <p className='text-center'>
              <Card.Link
                as={Link}
                to={Routes.Signin.path}
                className='text-gray-700'
              >
                <FontAwesomeIcon icon={faAngleLeft} className='me-2' /> Back to
                sign in
              </Card.Link>
            </p>
            <Col
              xs={12}
              className='d-flex align-items-center justify-content-center'
            >
              {phoneExists ? (
                <>
                  {isValidOTP ? (
                    <div className='bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                      <h3 className='mb-4'>Reset password</h3>
                      <Form onSubmit={onResetPassword}>
                        <Form.Group id='password' className='mb-4'>
                          {/* <Form.Label>Your Password</Form.Label> */}
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              type='password'
                              value={password}
                              name='password'
                              placeholder='Password'
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group id='confirmPassword' className='mb-4'>
                          {/* <Form.Label>Confirm Password</Form.Label> */}
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              type='password'
                              name='confirmPassword'
                              value={confirmPassword}
                              ref={confirmPasswordRef}
                              placeholder='Confirm Password'
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                isMatchRef.current =
                                  password === confirmPasswordRef.current.value;
                                // console.log(isMatchRef.current);
                              }}
                            />
                          </InputGroup>
                          {!isMatchRef.current && confirmPassword !== "" && (
                            <Form.Text
                              id='passwordHelpBlock'
                              muted
                              className='text-danger'
                            >
                              Passwords do not match!
                            </Form.Text>
                          )}
                        </Form.Group>
                        <Button
                          variant='primary'
                          type='submit'
                          className='w-100 text-uppercase'
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                              ></Spinner>
                              <span className='text-capitalize'>
                                {" "}
                                Resetting Password
                              </span>
                            </>
                          ) : (
                            "Reset password"
                          )}
                        </Button>
                      </Form>
                    </div>
                  ) : (
                    <div className='bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                      <Form onSubmit={onConfirmOTP}>
                        <Form.Group id='text' className='mb-4'>
                          <Form.Label>
                            An OTP has been sent to your phone number
                          </Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>

                            <Form.Control
                              type='text'
                              name='otp'
                              maxLength={6}
                              value={otp}
                              placeholder='Enter OTP'
                              onChange={(e) => setOtp(e.target.value)}
                            />
                          </InputGroup>
                          {otp.length < 6 && otp !== "" && (
                            <Form.Text
                              id='passwordHelpBlock'
                              muted
                              className='text-danger'
                            >
                              OTP should be 6 digits.
                            </Form.Text>
                          )}
                        </Form.Group>
                        <Button
                          variant='primary'
                          type='submit'
                          disabled={loading ? true : false}
                          className={`w-100 text-uppercase d-${
                            otp.length < 6 ? "none" : "block"
                          }`}
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                              ></Spinner>
                              <span className='text-capitalize'>
                                Validating OTP...
                              </span>
                            </>
                          ) : (
                            "Confirm OTP"
                          )}
                        </Button>
                      </Form>
                    </div>
                  )}
                </>
              ) : (
                <div className='bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                  <h3 className='mb-4'>Verify Phone Number</h3>
                  <Form onSubmit={onVerifyPhone}>
                    <Form.Group id='text' className='mb-4'>
                      <Form.Label>
                        Enter phone number linked to your account
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPhoneAlt} />
                        </InputGroup.Text>

                        <Form.Control
                          type='text'
                          name='phone'
                          maxLength={11}
                          value={phone}
                          placeholder='e.g 08012345679'
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </InputGroup>
                      {phone.length < 11 && phone !== "" && (
                        <Form.Text
                          id='passwordHelpBlock'
                          muted
                          className='text-danger'
                        >
                          Please include a valid Phone number.
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Button
                      variant='primary'
                      type='submit'
                      disabled={loading ? true : false}
                      className={`w-100 text-uppercase d-${
                        phone.length < 11 ? "none" : "block"
                      }`}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                          ></Spinner>
                          <span className='text-capitalize'>
                            {" "}
                            Sending OTP...
                          </span>
                        </>
                      ) : (
                        "Send OTP"
                      )}
                    </Button>
                  </Form>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ResetPassword;
