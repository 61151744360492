import { Modal, Table } from "@themesberg/react-bootstrap";
import moment from "moment";
import { formatAmount } from "../../Global";

export const DashboardModal = (props) => {
  const { data } = props;

  const TableRow = (props) => {
    const { product, meeting_summary, payment, created_at } = props;

    return (
      <tr>
        <td>
          <span className="fw-normal">{product && product.name}</span>
        </td>
        <td>
          <span className="fw-normal">
            {product && product.type === "Investment"
              ? formatAmount(product.amount, "investment")
              : formatAmount(product.amount)}
          </span>
        </td>
        <td>
          <span className="fw-normal">{product && product.type}</span>
        </td>
        <td>
          <span className="fw-normal">
            {meeting_summary && meeting_summary.substring(0, 50)}...
          </span>
        </td>
        <td>
          <span className="fw-normal">{payment}</span>
        </td>

        <td>
          <span className="fw-normal">
            {moment.utc(created_at).format("ll")}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          INSPECTION LIST
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Name</th>
              <th className="border-bottom">Amount</th>
              <th className="border-bottom">Type</th>
              <th className="border-bottom">Meeting</th>
              <th className="border-bottom">Payment</th>
              <th className="border-bottom">Created Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((list) => (
              <TableRow
                key={list.id}
                {...list}
                setModalShow={props.setModalShow}
              />
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};
