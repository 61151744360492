import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Col, Row } from "@themesberg/react-bootstrap";

import { Button, Form, Modal, Spinner } from "@themesberg/react-bootstrap";

import { updateUserRole } from "../actions/authActions";
import "./Users.css";
const UserModal = (props) => {
  const [user, setUser] = useState({
    roleId: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    position: "",
  });
  const { loading } = useSelector((state) => state.auth);
  const { roles } = useSelector((state) => state.role);

  const { firstname, lastname, phone, email, position, roleId } = user;

  const onSubmit = (e) => {
    e.preventDefault();
    props.dispatch(
      updateUserRole({
        id: props.user.id,
        firstname,
        lastname,
        email,
        phone,
        position,
        roleId,
        onHide: props.onHide,
      })
    );
  };

  useEffect(() => {
    setUser({
      firstname: props.user && props.user.firstname,
      lastname: props.user && props.user.lastname,
      phone: props.user && props.user.phone,
      email: props.user && props.user.email,
      position: props.user && props.user.position,
      roleId: props.user && props.user.roleId,
    });
  }, [props]);

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            ASSIGN ROLE TO USER
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group id='firstName'>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='firstname'
                  value={firstname}
                  placeholder='Enter Firstname'
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group id='lastName'>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type='text'
                  name='lastname'
                  value={lastname}
                  placeholder='Enter Lastname'
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col md={6} className='mb-3'>
              <Form.Group id='phone'>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type='number'
                  name='phone'
                  value={phone}
                  placeholder='Enter Phone'
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group id='emal'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  value={email}
                  placeholder='Enter Email'
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group>
                <Form.Label>Position</Form.Label>
                <Form.Control
                  type='text'
                  disabled
                  name='position'
                  value={position}
                  placeholder='Enter Position'
                  style={{ cursor: "not-allowed" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group id='gender'>
                <Form.Label>Role</Form.Label>
                <Form.Select name='roleId' value={roleId} onChange={onChange}>
                  <option value=''>Choose a Role</option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='text-white text-uppercase btn-block'
            variant='primary'
            type='submit'
          >
            {loading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' />
                <span className='text-capitalize'> Submitting</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UserModal;
