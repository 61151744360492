import {
  LOADING_TRANSACTION,
  GET_TRANSACTIONS,
  ADD_TRANSACTION,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION,
  FILTER_TRANSACTION,
  CLEAR_TRANSACTION,
  TRANSACTION_ERROR,
} from "../actions/types";

const INITIALSTATE = {
  transactions: [],
  transaction: null,
  filtered: null,
  loading: false,
  error: null,
};

const transactionReducer = (state = INITIALSTATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        transaction: payload,
        loading: false,
      };
    case ADD_TRANSACTION:
      return {
        ...state,
        // transaction: payload,
        loading: false,
      };
    case UPDATE_TRANSACTION:
      return {
        ...state,
        transactions: state.transactions.map((transaction) =>
          transaction.id === payload.id ? payload : transaction
        ),
        loading: false,
      };
    case FILTER_TRANSACTION:
      return {
        ...state,
        filtered: state.transactions.filter((transaction) => {
          const regex = new RegExp(`${payload}`, "gi");
          return (
            transaction.introduction.match(regex) ||
            transaction.meeting_summary.match(regex)
          );
        }),
        loading: false,
      };
    case DELETE_TRANSACTION:
      return {
        ...state,
        transactions: state.transactions.filter(
          (transaction) => transaction.id !== payload
        ),
        loading: false,
      };
    case CLEAR_TRANSACTION:
      return {
        ...state,
        transaction: null,
        loading: false,
      };
    case TRANSACTION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default transactionReducer;
