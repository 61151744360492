import {
  LOADING_LOCATION,
  GET_LOCATIONS,
  GET_LOCATION_DETAILS,
  ADD_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  FILTER_LOCATION,
  LOCATION_ERROR,
} from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { apiEndpoint } from "../Global";
import toast from "react-hot-toast";

// Load LOCATIONS
export const getLocations = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_LOCATION });
    const res = await axios.get(`${apiEndpoint}/locations`);

    dispatch({
      type: GET_LOCATIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOCATION_ERROR,
      // payload: err.response.data,
    });
  }
};

// Load Deals Count
export const getLocationCounts = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    // dispatch({ type: LOADING_DEAL });
    const res = await axios.get(`${apiEndpoint}/locations/dashboard`);

    dispatch({
      type: GET_LOCATION_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOCATION_ERROR,
      payload: err.response.data,
    });
  }
};

// Create LOCATION
export const createLocation = (location) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_LOCATION });
    const res = await axios.post(
      `${apiEndpoint}/locations/create`,
      location,
      config
    );

    dispatch({
      type: ADD_LOCATION,
      payload: res.data.data,
    });
    toast.success(res.data.message);
    location.onHide();
  } catch (err) {
    dispatch({
      type: LOCATION_ERROR,
      payload: err.response.data,
    });
  }
};

// UPDATE LOCATION
export const updateLocation = (location) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOADING_LOCATION });
    const res = await axios.put(
      `${apiEndpoint}/locations/update/${location.id}`,
      location,
      config
    );

    dispatch({
      type: UPDATE_LOCATION,
      payload: res.data.data,
    });
    toast.success(res.data.message);
    location.onHide();
  } catch (err) {
    dispatch({
      type: LOCATION_ERROR,
      payload: err.response.data,
    });
  }
};

// FILTER LOCATION
export const filteredLocation = (search) => (dispatch) =>
  dispatch({ type: FILTER_LOCATION, payload: search });

// DELETE PRODUCT
export const deleteLocation = (location) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({ type: LOADING_LOCATION });
    const res = await axios.delete(
      `${apiEndpoint}/locations/delete/${location.id}`
    );

    dispatch({
      type: DELETE_LOCATION,
      payload: location.id,
    });
    toast.success(res.data.message);
  } catch (err) {
    dispatch({
      type: LOCATION_ERROR,
      payload: err.response.data,
    });
  }
};
