import {
  LOADING_ROLE,
  GET_ROLES,
  ADD_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  FILTER_ROLE,
  ROLE_ERROR,
} from "../actions/types";

const INITIALSTATE = {
  roles: [],
  filtered: null,
  loading: false,
  error: null,
};

const roleReducer = (state = INITIALSTATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_ROLE:
      return {
        ...state,
        loading: true,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: payload,
        loading: false,
      };
    case ADD_ROLE:
      return {
        ...state,
        roles: [payload, ...state.roles],
        loading: false,
      };
    case UPDATE_ROLE:
      return {
        ...state,
        roles: state.roles.map((role) =>
          role.id === payload.id ? payload : role
        ),
        loading: false,
      };
    case FILTER_ROLE:
      return {
        ...state,
        filtered: state.roles.filter((role) => {
          const regex = new RegExp(`${payload}`, "gi");
          return role.name.match(regex);
        }),
        loading: false,
      };
    case DELETE_ROLE:
      return {
        ...state,
        roles: state.roles.filter((role) => role.id !== payload),
        loading: false,
      };
    case ROLE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default roleReducer;
