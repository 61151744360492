import {
  ADD_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM,
  GET_TEAMS,
  TEAMS_ERROR,
  LOADING_TEAMS,
  FILTER_TEAMS,
  SET_CURRENT,
  CLEAR_CURRENT,
} from "../actions/types";

const initialState = {
  teams: [],
  nextPage: "",
  previousPage: "",
  perPage: 0,
  totalPages: 0,
  filtered: null,
  current: null,
  loading: false,
  error: null,
};

const teamReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TEAMS:
      return {
        ...state,
        teams: payload.data,
        nextPage: payload.next_page_url,
        previousPage: payload.previous_page_url,
        perPage: payload.per_page,
        totalPages: payload.totalPages,
        loading: false,
      };
    case ADD_TEAM:
      return {
        ...state,
        teams: [payload, ...state.teams],
        current: null,
        loading: false,
      };
    case UPDATE_TEAM:
      return {
        ...state,
        teams: state.teams.map((team) =>
          team.id === payload.id ? payload : team
        ),
        current: null,
        loading: false,
      };
    case FILTER_TEAMS:
      return {
        ...state,
        filtered: state.teams.filter((team) => {
          const regex = new RegExp(`${payload}`, "gi");
          return team.name.match(regex);
        }),
        loading: false,
      };
    case DELETE_TEAM:
      return {
        ...state,
        teams: state.teams.filter((team) => team.id !== payload.id),
        current: null,
        loading: false,
      };
    case TEAMS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        current: null,
      };
    case LOADING_TEAMS:
      return {
        ...state,
        loading: true,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: payload,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
};

export default teamReducer;
